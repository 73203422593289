/**
 * DynamicInventoryInput Inputbase
 */
export class InputBase<T> {
    value: T;
    key: string;
    label: string;
    required: boolean;
    order: number;
    controlType: string;
    type: string;
    maxLength: number;
    options: { key: string, value: string }[];

    /**
     * Assign options value to Local Variables.
     * @param options Options which can be used in Sub Class.
     */
    constructor(options: {
        value?: T;
        key?: string;
        label?: string;
        required?: boolean;
        order?: number;
        controlType?: string;
        type?: string;
        maxLength?: number;
        options?: { key: string, value: string }[];
    } = {}) {
        this.value = options.value;
        this.key = options.key || '';
        this.label = options.label || '';
        this.required = !!options.required;
        this.order = options.order === undefined ? 1 : options.order;
        this.controlType = options.controlType || '';
        this.type = options.type || '';
        this.maxLength = options.maxLength || 999;
        this.options = options.options || [];
    }
}
