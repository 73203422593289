import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { forkJoin, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PlatformService } from 'src/app/shared/services/platform.service';
import { DemandService } from '../../feature-shared/service/demand.service';
import { VehicleService } from '../../feature-shared/service/vehicle.service';
/**
 * AllDemand Component
 */
@Component({
  selector: 'app-all-demand-view',
  templateUrl: './all-demand-view.component.html',
  styleUrls: ['./all-demand-view.component.scss'],
})
export class AllDemandViewComponent implements OnInit {
  isDesktop:boolean;
  vehicles$: Observable<any>;
  demandlist$: Observable<any>;
  vehicles: any[];

  /**
   * Setup Dependancy Injections
   */
  constructor(private platformService: PlatformService, private vehicleService: VehicleService, private router:Router, private demandService: DemandService) {
    this.vehicles$ = this.vehicleService.getVehicles();
    this.demandlist$ = this.demandService.getDemandList();
   }

  /**
   * On Init ForkJoin will return New observable with emitted Value from two Array in Exact Order that we passed.
   */
  ngOnInit() {
    this.isDesktop = this.platformService.isDesktop();
    forkJoin([
      this.vehicles$,
      this.demandlist$,
   ])
   .pipe(
       map(([vehicles, demands]) => {
          this.vehicles =   vehicles.data.filter(country => demands.data.find(c => c.vehicle === country.vehicle_id));
       })
   ).subscribe();
  }

  /**
   * Show Demand Detail of Selected Vehicle For IonicApp(Mobile).
   * @param selectedVehicle Optional Parameter for Vehicle inventory View.
   */
  showDetail(selectedVehicle?: string){
    this.router.navigate(['/demandDetails/'  + selectedVehicle], {
      state: {
        vehicle: selectedVehicle
      }
    })
  }

}
