import { TranslateLoader } from "@ngx-translate/core";
import { from, Observable } from "rxjs";
import { Http } from '@capacitor-community/http';
import { map } from "rxjs/operators";
import { environment } from "src/environments/environment";


export class SortimoTranslationLoader implements TranslateLoader {

    constructor() {}
    baseUrl = environment.baseUrl;

    /**
     * Get Translation from Hybris Translation Tool.
     * @param lang Language
     * @returns Observable
     */
    public getTranslation(lang: String): Observable<any> {
        var language = languages.get(lang.valueOf());
        const options ={ url : this.baseUrl+language.topLevelDomain+"/sortimoknots/api/v2/translations/myInventory/"+language.topLevelDomain}
        const response =  Http.request({ ...options, method: 'GET' })
        return from(response).pipe(map(
            res => {return res.data}
        ))
    }

    /**
     * Get Translation from Hybris Translation Tool.(Not Used Currently)
     * @param lang Language
     * @returns Promise
     */
    public async getTranslationInService(lang: String)  {
        var language = languages.get(lang.valueOf());
        const options ={ url : this.baseUrl+language.topLevelDomain+"/sortimoknots/api/v2/translations/myInventory/"+language.topLevelDomain}
        const response = await Http.request({ ...options, method: 'GET' })
        return response.data
    }

  }

/**
 * mapped translation keys to domains.
 */  
let languages = new Map <string,LanguageParams>();
languages.set("Cy-az-AZ",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("Cy-sr-SP",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("Cy-uz-UZ",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("Lt-az-AZ",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("Lt-sr-SP",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("Lt-uz-UZ",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("aa",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("ab",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("ae",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("af",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("af-ZA",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("ak",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("am",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("an",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("ar",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("ar-AE",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("ar-BH",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("ar-DZ",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("ar-EG",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("ar-IQ",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("ar-JO",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("ar-KW",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("ar-LB",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("ar-LY",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("ar-MA",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("ar-OM",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("ar-QA",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("ar-SA",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("ar-SY",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("ar-TN",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("ar-YE",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("as",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("av",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("ay",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("az",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("ba",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("be",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("be-BY",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("bg",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("bg-BG",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("bh",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("bi",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("bm",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("bn",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("bo",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("br",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("bs",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("ca",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("ca-ES",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("ce",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("ch",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("co",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("cr",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("cs",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("cs-CZ",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("cu",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("cv",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("cy",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("da",{
    topLevelDomain:"dk",
    languageKey:"da"
});
languages.set("da-DK",{
    topLevelDomain:"dk",
    languageKey:"da"
});
languages.set("de",{
    topLevelDomain:"de",
    languageKey:"de"
});
languages.set("de-AT",{
    topLevelDomain:"de",
    languageKey:"de_AT"
});
languages.set("de-CH",{
    topLevelDomain:"de",
    languageKey:"de"
});
languages.set("de-DE",{
    topLevelDomain:"de",
    languageKey:"de"
});
languages.set("de-LI",{
    topLevelDomain:"de",
    languageKey:"de"
});
languages.set("de-LU",{
    topLevelDomain:"de",
    languageKey:"de"
});
languages.set("div-MV",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("dv",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("dz",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("ee",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("el",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("el-GR",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("en",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("en-AU",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("en-BZ",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("en-CA",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("en-CB",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("en-GB",{
    topLevelDomain:"co.uk",
    languageKey:"en_UK"
});
languages.set("en-IE",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("en-JM",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("en-NZ",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("en-PH",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("en-TT",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("en-US",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("en-ZA",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("en-ZW",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("eo",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("es",{
    topLevelDomain:"es",
    languageKey:"es"
});
languages.set("es-AR",{
    topLevelDomain:"es",
    languageKey:"es"
});
languages.set("es-BO",{
    topLevelDomain:"es",
    languageKey:"es"
});
languages.set("es-CL",{
    topLevelDomain:"es",
    languageKey:"es"
});
languages.set("es-CO",{
    topLevelDomain:"es",
    languageKey:"es"
});
languages.set("es-CR",{
    topLevelDomain:"es",
    languageKey:"es"
});
languages.set("es-DO",{
    topLevelDomain:"es",
    languageKey:"es"
});
languages.set("es-EC",{
    topLevelDomain:"es",
    languageKey:"es"
});
languages.set("es-ES",{
    topLevelDomain:"es",
    languageKey:"es"
});
languages.set("es-GT",{
    topLevelDomain:"es",
    languageKey:"es"
});
languages.set("es-HN",{
    topLevelDomain:"es",
    languageKey:"es"
});
languages.set("es-MX",{
    topLevelDomain:"es",
    languageKey:"es"
});
languages.set("es-NI",{
    topLevelDomain:"es",
    languageKey:"es"
});
languages.set("es-PA",{
    topLevelDomain:"es",
    languageKey:"es"
});
languages.set("es-PE",{
    topLevelDomain:"es",
    languageKey:"es"
});
languages.set("es-PR",{
    topLevelDomain:"es",
    languageKey:"es"
});
languages.set("es-PY",{
    topLevelDomain:"es",
    languageKey:"es"
});
languages.set("es-SV",{
    topLevelDomain:"es",
    languageKey:"es"
});
languages.set("es-UY",{
    topLevelDomain:"es",
    languageKey:"es"
});
languages.set("es-VE",{
    topLevelDomain:"es",
    languageKey:"es"
});
languages.set("et",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("et-EE",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("eu",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("eu-ES",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("fa",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("fa-IR",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("ff",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("fi",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("fi-FI",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("fj",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("fo",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("fo-FO",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("fr",{
    topLevelDomain:"fr",
    languageKey:"fr"
});
languages.set("fr-BE",{
    topLevelDomain:"be",
    languageKey:"fr_BE"
});
languages.set("fr-CA",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("fr-CH",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("fr-FR",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("fr-LU",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("fr-MC",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("fy",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("ga",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("gd",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("gl",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("gl-ES",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("gn",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("gu",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("gu-IN",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("gv",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("ha",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("he",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("he-IL",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("hi",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("hi-IN",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("ho",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("hr",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("hr-HR",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("ht",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("hu",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("hu-HU",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("hy",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("hy-AM",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("hz",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("ia",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("id",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("id-ID",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("ie",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("ig",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("ii",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("ik",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("io",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("is",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("is-IS",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("it",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("it-CH",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("it-IT",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("iu",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("ja",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("ja-JP",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("jv",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("ka",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("ka-GE",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("kg",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("ki",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("kj",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("kk",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("kk-KZ",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("kl",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("km",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("kn",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("kn-IN",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("ko",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("ko-KR",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("kr",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("ks",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("ku",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("kv",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("kw",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("ky",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("ky-KZ",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("la",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("lb",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("lg",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("li",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("ln",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("lo",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("lt",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("lt-LT",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("lu",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("lv",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("lv-LV",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("mg",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("mh",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("mi",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("mk",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("mk-MK",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("ml",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("mn",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("mn-MN",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("mr",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("mr-IN",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("ms",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("ms-BN",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("ms-MY",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("mt",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("my",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("na",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("nb",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("nb-NO",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("nd",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("ne",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("ng",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("nl",{
    topLevelDomain:"nl",
    languageKey:"nl"
});
languages.set("nl-BE",{
    topLevelDomain:"be",
    languageKey:"nl_BE"
});
languages.set("nl-NL",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("nn",{
    topLevelDomain:"no",
    languageKey:"no"
});
languages.set("nn-NO",{
    topLevelDomain:"no",
    languageKey:"no"
});
languages.set("no",{
    topLevelDomain:"no",
    languageKey:"no"
});
languages.set("nr",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("nv",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("ny",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("oc",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("oj",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("om",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("or",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("os",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("pa",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("pa-IN",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("pi",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("pl",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("pl-PL",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("ps",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("pt",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("pt-BR",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("pt-PT",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("qu",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("rm",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("rn",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("ro",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("ro-RO",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("ru",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("ru-RU",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("rw",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("sa",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("sa-IN",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("sc",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("sd",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("se",{
    topLevelDomain:"se",
    languageKey:"sv_SE"
});
languages.set("sg",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("si",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("sk",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("sk-SK",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("sl",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("sl-SI",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("sm",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("sn",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("so",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("sq",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("sq-AL",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("sr",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("ss",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("st",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("su",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("sv",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("sv-FI",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("sv-SE",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("sw",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("sw-KE",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("ta",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("ta-IN",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("te",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("te-IN",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("tg",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("th",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("th-TH",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("ti",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("tk",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("tl",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("tn",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("to",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("tr",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("tr-TR",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("ts",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("tt",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("tt-RU",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("tw",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("ty",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("ug",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("uk",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("uk-UA",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("ur",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("ur-PK",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("en-US",{
    topLevelDomain:"us",
    languageKey:"en_US"
});
languages.set("uz",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("ve",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("vi",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("vi-VN",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("vo",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("wa",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("wo",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("xh",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("yi",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("yo",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("za",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("zh",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("zh-CHS",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("zh-CHT",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("zh-CN",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("zh-HK",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("zh-MO",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("zh-SG",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("zh-TW",{
    topLevelDomain:"de",
    languageKey:"en"
});
languages.set("zu",{
    topLevelDomain:"de",
    languageKey:"en"
});

type LanguageParams = {
    topLevelDomain:string,
    languageKey:string
}



