import { Component } from '@angular/core';

import { NavController, Platform } from '@ionic/angular';

import { SplashScreen } from '@capacitor/splash-screen';
import { StatusBar, Style } from '@capacitor/status-bar';

import { AuthActions, AuthService, IAuthAction } from 'ionic-appauth';
import { TranslateService } from '@ngx-translate/core';
import { PlatformService } from './shared/services/platform.service';
import { Subscription } from 'rxjs';
import { Device  } from '@capacitor/device';
import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';
import { CustomAuthService } from './auth/customauth.service';
import { NotificationsService } from './shared/services/notifications.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html'
})
/**
 * This is AppComponent class
 */
export class AppComponent {
  isDesktop: boolean;
  sub: Subscription;
  action: IAuthAction;

  /**
   * Using Domsanitizer To bypassSecurityTrust For SVG.
   * calling initializeApp Method To start Executing Authentification Process.
   * setup Dependancy Injections
  */
  constructor(
    private platform: Platform,
    private auth: CustomAuthService,
    private platformService: PlatformService,
    private navCtrl: NavController,
    private translate: TranslateService,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private pushNotification: NotificationsService
  ) {
    this.sub = this.auth.events$.subscribe((action) => this.onSignInSuccess(action));

    this.matIconRegistry.addSvgIcon(
      "field_error",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icons/field_error.svg")
   );
   this.matIconRegistry.addSvgIcon(
      "field_success",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icons/field_success.svg")
   );
   this.matIconRegistry.addSvgIcon(
      "menu_add",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icons/menu_add.svg")
   );
   this.matIconRegistry.addSvgIcon(
      "menu_edit",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icons/menu_edit.svg")
   );

    this.initializeApp();
    this.pushNotification.initPush();
  }

  /**
   * Detects The Platform(web, mobile etc..).
   * Run intial Auth Process With init() from AuthService.
   * Set Translation Language.
   * @returns The processed target number
   */
  initializeApp() {
    this.platform.ready().then(async () => {
      await this.auth.init();
      if (this.platform.is('mobile') && !this.platform.is('mobileweb')) {
        await SplashScreen.hide();
        StatusBar.setStyle({ style: Style.Default }).catch();
      }
      this.translate.setDefaultLang('en');
      Device.getLanguageCode().then((language)=>{
        this.translate.use(language.value);
     });
    });
  }

  async ngOnInit() {
    this.isDesktop = this.platformService.isDesktop();
  }

    /**
   * If SignedIn Successfully and Token fromStorage loaded it Navigates to Home page.
   */
  private onSignInSuccess(action: IAuthAction) {
    this.action = action;
    if (action.action === AuthActions.SignInSuccess ||
      action.action === AuthActions.LoadTokenFromStorageSuccess) {
      this.navCtrl.navigateRoot('home');
    }
  }



}
