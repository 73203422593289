import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { PlatformService } from 'src/app/shared/services/platform.service';
import { InventoryService } from '../feature-shared/service/inventory.service';
import { SchemaService } from '../feature-shared/service/schema.service';
import {animate,state,style,transition,trigger} from '@angular/animations';
import { InventoryInputElement } from 'src/app/shared/models/inventory';
import { SelectionModel } from '@angular/cdk/collections';
import { StateService } from '../feature-shared/service/state.service';
import { MatSort } from '@angular/material/sort';
import { IonItemSliding, ModalController } from '@ionic/angular';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { CreateInventoryComponent } from './create-inventory/create-inventory.component';
import { AssignInventoryComponent } from './assign-inventory/assign-inventory.component';
import { VehicleService } from '../feature-shared/service/vehicle.service';
import { TranslateService } from '@ngx-translate/core';
import { InventoryImageUploadComponent } from './inventory-image-upload/inventory-image-upload.component';
import { MatDialog } from '@angular/material/dialog';
//import { firstValueFrom } from 'rxjs';


/**
 * Inventory Component Material Table Animation For Row Expansion.
 */
@Component({
  selector: 'app-inventory',
  templateUrl: './inventory.component.html',
  styleUrls: ['./inventory.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('*', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition(
        '* <=> collapsed',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
      ),
    ]),
  ],
})

/**
 * Inventory Component Class.
 */
export class InventoryComponent implements OnInit{
  inventories: any[] = [];
  isDesktop: boolean;
  selectedInventories:any[];
  vehicleid: string;
  //subscriptionOfInventoryService : Subscription;
  vehicleName: string;
  inventory$: Observable<any>;
  vehicle: any[];
  filter:boolean = false;
  query: any = '';
  expandedElement: any;
  displayedColumns: string[];
  filteredColumns: string[];
  allColumns: string[];
  dataSource: MatTableDataSource<any> = new MatTableDataSource();
  selection = new SelectionModel<InventoryInputElement>(true, []);
  @ViewChild(MatSort) sort: MatSort;

  imageURL;


  /**
   * setup Dependency Injections.
   */
  constructor(
    private route: ActivatedRoute,
    private inventoryService: InventoryService,
    private schemaService: SchemaService,
    private platformService: PlatformService,
    private stateService: StateService,
    private router:Router,
    private translate: TranslateService,
    private vehicleService: VehicleService,
    public modalController: ModalController,
    public dialog: MatDialog
  ) {
    this.getColumns();
    this.stateService.selectedInventories.subscribe((inventories)=>{
      this.selectedInventories = inventories;
    });
  }

  /**
   * @param {string} quantity  The quantity to process.
   * @returns The processed Text Of Quantity From Character Vaue.
   */
  getQuantityText(quantity: string){
    if(quantity == 'Q'){
      return this.translate.instant("myInventory.data.quantity");
    }
    else if(quantity == 'M'){
      return this.translate.instant("myInventory.data.meters");
    } 
    else if(quantity == 'L'){
      return this.translate.instant("myInventory.data.liters");
    }
    else if(quantity == 'K'){
      return  this.translate.instant("myInventory.data.kilograms");
    }
  }

  /**
   * Lifecycle Method OnInit:
   * 
   * Checks if VehicleId is in Route Param Then opens that vehicles Assigned Inventory View.
   * 
   * If No VehicleId Then Load Total Inventory.
   */
  ngOnInit() {
    this.vehicleService.getVehicles().subscribe((vehicles)=>{
      this.vehicle = vehicles.data;
    })
    this.inventoryService.getCentralInventory().subscribe(result => this.inventories = result.data);
    this.vehicleid = this.route.snapshot.paramMap.get('id');
    if(this.vehicleid){
      this.vehicleService.getVehicles().subscribe((vehicles)=>{
        this.vehicleName = vehicles.data.filter(vehicle => vehicle.vehicle_id == this.vehicleid).map((vehicle)=>vehicle.vehicle_name)
     })
    setTimeout(() => {
      this.inventoryService.getInventoriesVehicleInventory(this.vehicleid).subscribe(inventoryElements =>{         
       const vehicleInventories = this.inventories.filter(inventory => {
         return inventoryElements.data.filter((inventoryElement) => { 
          return inventoryElement.inventory == inventory.uid && inventoryElement.vehicle == this.vehicleid 
          }).length > 0
       })

       const changedVehicleInventories = this.inventoryService.mapInventoryElementsIntoInventories(vehicleInventories, inventoryElements.data, this.vehicleid);
        if(this.isDesktop){
            this.dataSource.data = changedVehicleInventories;
            this.dataSource.sort = this.sort;
        }
        else{
            this.inventories = changedVehicleInventories;
        }
        this.selection.clear();
      })
    }, 1000)
    }else {
        this.inventoryService.getCachedInventoriesOfCentralInventory().subscribe(inventoriesResult => {
          const inventories = inventoriesResult;
          if(this.isDesktop){
            this.dataSource.data = inventories;
            this.dataSource.sort = this.sort;
          }
          else{
            this.inventories = inventories
          }
          this.selection.clear();
        })
    }
  }

  /**
   * Subscribes to  "getInventorySchema" and Filters The inventory Data According to Table view Requirements.
   * 
   * Filtered Table Columns.
   */
  getColumns() {
    this.isDesktop = this.platformService.isDesktop();
    this.schemaService.getInventorySchema().subscribe((inventory) => {
      this.allColumns = inventory.data
        .filter(
          (allcolumns) =>
            !allcolumns.required &&
            !allcolumns.id?.substr(allcolumns.id?.length - 2).includes('id') &&
            !allcolumns.id?.includes('parent') &&
            !allcolumns.id?.includes('price_value') &&
            !allcolumns.id?.includes('target_amount')&&
            !allcolumns.id?.includes('supplier_article_number')&&
            !allcolumns.id?.includes('image_url')
        )
        .map(({ id }) => id);
      this.filteredColumns = inventory.data
        .filter(
          (u) =>
            !u.id?.substr(u.id?.length - 2).includes('id') &&
            !u.id?.includes('parent') &&
            !u.id?.includes('unit') &&
            u.required || 
            u.id.includes('target_amount')
        )
        .map(({ id }) => id);
      this.displayedColumns = ['select', ...this.filteredColumns, 'unit','actions'];
    });
  }

  /**
   * Updates The Table Value according To Filtered Value.
   */
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  /**
   * Toggle Selection For All Rows.
   */
  masterToggle() {
    this.isAllSelected()
      ? this.selection.clear()
      : this.dataSource.data.forEach((row) => this.selection.select(row));
    this.setSelecteditems(this.selection.selected);
  }

  /**
   * Toggle Selection For selectedRow.
   */
  selectionToggle(row: InventoryInputElement) {
    this.selection.toggle(row);
    this.setSelecteditems(this.selection.selected);
  }

  /**
   * Checks if All Rows are Selected or not.
   */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /**
   * @ignore
   */
  checkboxLabel(row?: InventoryInputElement) {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${
      row.id + 1
    }`;
  }
  
  /**
   * Set SelectedInventory To State Service.
   */
  setSelecteditems(value) {
    this.stateService.setSelectedInventory(value);
  }

  /**
   * Shows Search Input in Mobile view.
   */
  showSearch(){
    this.filter = true;
  }

  /**
   * Hides Search Input in Mobile view.
   */
  hideSearch(){
      this.filter = false;
      this.query = '';
  }
  /**
   * @ignore
   */
  onBlur(){
      this.query = '';
      this.hideSearch();
  }
  
  /** 
   * Cancels Selected Rows
   */
  cancelSelection(){
    this.inventories.forEach(child => {
      child.isChecked = false
      
    })
  }

  /**
   * @param {any} inv Inventory Object.
   * @param {IonItemSliding} slidingItem An Slide event which takes place in the Mobile View.
   * Navigate To inventory Detail Page.
   */
  goToDetailOf(inv, slidingItem?: IonItemSliding) {
    this.router.navigate(['/inventoryDetails/' + inv.uid], {
      state: {
        inventory: inv,
      },
    });
    slidingItem.close();
  }

  /**
   * @param {any} inv Inventory Object.
   * @param {IonItemSliding} slidingItem An Slide event which takes place in the Mobile View.
   * Subscribes to the deleteInventories Observable in Inventory Service if More than one else deleteInventory Observable from Service.
   */
  deleteItem(inv?:any, slidingItem? :IonItemSliding){
    if(this.selectedInventories.length > 0){
      this.inventoryService.deleteInventories(this.selectedInventories).subscribe()
    }
    else{
        this.inventoryService.deleteInventory(inv)
        slidingItem.close();
    } 
  }

  fabActionButton(){
    if(this.isDesktop){
      return true
    }
    return this.selectedInventories.length <= 0 && !this.isDesktop
  }
  /**
   * Creates Edit Inventory Modal For Selected Inventory and Presents it.
   * @returns Promise<void>
   */
  async editInventory() {
    const modal = await this.modalController.create({
        component: CreateInventoryComponent,
        componentProps: {
            inventory : this.selectedInventories[0]
          }
      });

    await modal.present();
    await modal.onDidDismiss().then(resolve => {
        const inv = resolve.data.inventory
        this.router.navigate(['/inventoryDetails/' + inv.uid], {
            state: {
                inventory: inv
              }
        }).then((resolve)=> {
          console.log(this.selectedInventories)
          })
    });
  }

   /**
   * Creates Assign Inventory Modal For Selected Inventory and Presents it.
   * @returns Promise<void>
   */
  async assignInventory() {
    const modal = await this.modalController.create({
        component: AssignInventoryComponent,
        componentProps: {
            inventories : this.selectedInventories,
          }
      });

    await modal.present();
    await modal.onDidDismiss().then(resolve => {
        this.router.navigate(['/inventory']);
    });
  }

  ngOnDestroy(): void {
   // this.subscriptionOfInventoryService.unsubscribe();
  }

  /**
  * Open Assign inventory Dialog
  */
  openInventoryImageUploadDlg(element){
    const dialogRef = this.dialog.open(InventoryImageUploadComponent,{restoreFocus: false,data:{element:element}});
    dialogRef.disableClose = true;
  }


  deleteImage(element){
    element.image_url = null;
    this.inventoryService.updateInventory(element)
  }
}
