import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FeatureSharedModule } from '../feature-shared/feature-shared.module';
import { MaterialModule } from 'src/app/shared/material/material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from 'src/app/shared/shared.module';
import { DemandComponent } from './demand.component';
import { AllDemandViewComponent } from './all-demand-view/all-demand-view.component';
import { VehicleDemandListComponent } from './all-demand-view/vehicle-demand-list/vehicle-demand-list.component';
import { DemandDetailViewComponent } from './all-demand-view/demand-detail-view/demand-detail-view.component';
import { DemandListComponent } from './demand-list/demand-list.component';
import { OpenDemandInputComponent } from './demand-list/open-demand-input/open-demand-input.component';
import { NgxPrintModule } from 'ngx-print';
import { PopoverComponent } from './demand-list/popover/popover.component';


@NgModule({
  declarations: [
    DemandComponent,
    AllDemandViewComponent,
    VehicleDemandListComponent,
    DemandDetailViewComponent,
    DemandListComponent,
    OpenDemandInputComponent,
    PopoverComponent
  ],
  imports: [
    CommonModule,
    FeatureSharedModule,
    MaterialModule,
    FormsModule,
    IonicModule,
    ReactiveFormsModule,
    TranslateModule,
    SharedModule,
    NgxPrintModule
  ],
  exports:[AllDemandViewComponent, DemandComponent, DemandListComponent ]
})
export class DemandModule { }
