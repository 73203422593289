import { Injectable } from '@angular/core';
import { shareReplay } from 'rxjs/operators';
import { from, Observable } from 'rxjs';
import { AuthHttpService } from 'src/app/auth/auth-http.service';
import { environment } from 'src/environments/environment';

/**
 * Schema Service For Inventory
 */
@Injectable({
  providedIn: 'root'
})
export class SchemaService {

  backendURL = environment.backendUrlRoot + 'api/';
  data: Observable<any>;
  
  constructor(private authhttp: AuthHttpService) {
  }

  /**
   * Fetch Inventory Schema.
   * @returns Observable
   */
  getInventorySchema(){
      if(!this.data){
          setTimeout(()=>{this.data=undefined},3600000);
          const response = this.authhttp.interceptHttpRequest(this.backendURL + 'get/schema/inventory', 'GET');
          this.data = from(response).pipe(shareReplay(1));
      }
      return this.data;
  }
}

/**
 * Schema Type
 */
export type Schema = {
        id: string;
        type?: string;
        required?: boolean;
        help_text?: string;
        max_length?: number;
        choices?: SchemaOptions[];
}

/**
 * SchemaOptions For Choices
 */
export type SchemaOptions = {
    value: string;
    display_name: string;
}


