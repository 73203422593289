import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

/**
 * State Managemant Services
 */
@Injectable({
  providedIn: 'root'
})
export class StateService {
  selectedInventories: BehaviorSubject<any> = new BehaviorSubject([]);
  constructor() {
   }

  /**
  * Sets Selected Inventories to BehaviourSubject so we can access it When we subscribe.
  * @param value Selected Inventory Value
  */
  setSelectedInventory(value:any[])
  {
    this.selectedInventories.next(value);
  }

  /**
   * First Selected Inventory Value
   */
  retrieveFirstSelectedInventory(){
      return this.selectedInventories.value[0];
  }

}
