import { Pipe, PipeTransform } from "@angular/core";

/**
 * Pipe is Used  in Messages View to Group messages with Today, Yesterday and Older.
 */
@Pipe({
  name: "groupBy",
})
export class GroupByPipe implements PipeTransform {
  public transform(value: Array<any>, field: string): Array<any> {
    var groupedObj;
    var key;
    if (!value) {
      return null;
    }
    if (field == "timestamp") {
      groupedObj = value.reduce((prev, cur) => {
      var currentDate = new Date()
      var toFormat = new Date(cur[field])
      if(toFormat.getDate() == currentDate.getDate() && toFormat.getMonth() == currentDate.getMonth() && toFormat.getFullYear() == currentDate.getFullYear() ) {
        key =  'myInventory.allmessages.header.today'
      }
      else if(toFormat.getDate() == (currentDate.getDate() - 1) && toFormat.getMonth() == currentDate.getMonth() && toFormat.getFullYear() == currentDate.getFullYear()) {
        key= 'myInventory.allmessages.header.yesterday'
      }
      else{
        key= 'myInventory.allmessages.header.older'
      }
      if (!prev[key]) {
        prev[key] = [cur];
      } else {
        prev[key].push(cur);
      }
       return prev;
      }, {});
    } else {
      groupedObj = value.reduce((prev, cur) => {
        if (!prev[cur[field]]) {
          prev[cur[field]] = [cur];
        } else {
          prev[cur[field]].push(cur);
        }
        return prev;
      }, {});
    }
    return Object.keys(groupedObj).map((key) => ({
      key,
      value: groupedObj[key],
    }));
  }
}
