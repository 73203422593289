import { UntypedFormGroup } from '@angular/forms';
import { Component, Inject, Input, OnInit, Optional, Output } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NavParams } from '@ionic/angular';
import { InventoryService } from '../../service/inventory.service';

/**
 * Associate Vehicle Input Component.
 * SubComponent of AssociateVehicle Component
 */
@Component({
  selector: 'associate-vehicle-input',
  templateUrl: './associate-vehicle-input.component.html',
  styleUrls: ['./associate-vehicle-input.component.scss'],
})
export class AssociateVehicleInputComponent implements OnInit {
   @Input() vehicle;
   @Input() form:UntypedFormGroup;
   @Input() unit;
    isChecked:boolean;
   @Input() value: string;
    string;

    affectedInventory: any;
    filteredInventoryElements: any[] =[];
    amount: string;

  /**
   * @param inventoryService 
   * @param data Matdialogdata ref to use data that was passed in to a dialog.
   * @param navParams it is similar to MatDialogData but for Ionic(Mobile) App.
   * Optional decorator marks the parameter as being an optional dependency.
   * 
   * Navparams and DialogData are Optional here as you can have only one at a time.
   */
  constructor(private inventoryService:InventoryService, @Optional() @Inject(MAT_DIALOG_DATA) data, @Optional() private navParams: NavParams) {
    this.affectedInventory = data?.inventory;
    this.inventoryService.getInventoryElement().subscribe((result)=> {
      this.filteredInventoryElements = result.data.filter(inventoryElement => {
          return inventoryElement.inventory == this.affectedInventory?.uid;
      });
      this.associatedVehicle(this.filteredInventoryElements);
    })
  }

  /**
   * Sets a targetamount Value and checkboy value to form Controls.
   * @param inventoryElement It contains Inventory Element of Selected Inventory.
   */
  associatedVehicle(inventoryElement: any[]){
    inventoryElement?.map(invElement=> {
      if(invElement.vehicle == this.vehicle.vehicle_id){
        this.form.controls[invElement.vehicle+"_checked"].setValue(true);
        this.form.controls[this.vehicle.vehicle_id+"_amount"].setValue(invElement.target_amount)      
        this.form.controls[this.vehicle.vehicle_id+"_amount"].enable()
      }
    })
  }

  ngOnInit() {
      this.string = this.vehicle.vehicle_id+"_checked"
  }

  /**
   * Handles Value of target amount on Checkbox Change Event.
   */
  checkBoxChange($event){
      if(this.form.controls[this.vehicle.vehicle_id+"_checked"].value){
        this.form.controls[this.vehicle.vehicle_id+"_amount"].enable()
      }
      else {
        this.form.controls[this.vehicle.vehicle_id+"_amount"].disable()
        this.form.controls[this.vehicle.vehicle_id+"_amount"].setValue(0);
      }
  }



}
