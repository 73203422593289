import { Component, Input, OnInit, Self, ElementRef, HostListener, ViewChild, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { VehicleService } from '../../../service/vehicle.service';
import { Icons, Colors, NavigationMenuItemData } from '../navigation-menu-item/navigation-menu-item.component';

/**
 * VehicleScrollContainer Component.
 */
@Component({
    selector: 'app-vehicle-scroll-container',
    templateUrl: './vehicle-scroll-container.component.html',
    styleUrls: ['./vehicle-scroll-container.component.scss'],
})
export class VehicleScrollContainerComponent implements OnInit, OnDestroy {

    vehicles: any[] = [];
    icons = Icons;
    colors = Colors;
    offset:number;
    widths = new BehaviorSubject<any>({
        0: 0
    });
    vehicles$: Observable<any>;

    widthsSubscription:Subscription;

    leftDisabled=true;
    rightDisabled = false;

    screenHeight;
    screenWidth;

    @ViewChild("scrollContainer") private scrollContainerRef: ElementRef<HTMLElement>;
    @ViewChild("scrollAbleMenu") private scrollAbleMenu: ElementRef<HTMLElement>;


    @HostListener('window:scroll', ['$event'])
    scrollHandler(event) {
          if(event.srcElement.scrollLeft>0){
            this.leftDisabled=false;
          }else{
            this.leftDisabled=true;
          }
          if(event.srcElement.scrollLeft == event.srcElement.scrollWidth-event.srcElement.clientWidth){
              this.rightDisabled=true;
          }else{
            this.rightDisabled=false;
          }
        }



    @HostListener('window:resize', ['$event'])
    onResize(event?) {
        this.setWidthForSCSSVar();
    }

    width = 0;

    /**
     * Input Decorator for Setting Offset.
     */
    @Input() set offSet(offSet) {
        this.offset = offSet;
    }

    /**
     * Assign Observable.
     * @param element DomElement Referance.
     */
    constructor(@Self() private element: ElementRef, private vehicleService: VehicleService) {
        this.vehicles$ = this.vehicleService.getVehicles();
     }
    
    /**
     * Subscribe to Vehicle Observable.
     * 
     * Logic For Scrolling Vehicle through Vehicle List.
     */
    ngOnInit() {
        this.vehicles$.subscribe(vehicles=> this.vehicles = vehicles.data);
        this.widthsSubscription = this.widths.subscribe(widths => {
            this.width = Object.values(widths).reduce((a: number, b: number) => { return a + b }) as number;
            this.element.nativeElement.style.setProperty('--containerWidth', this.width + 'px');
            this.setWidthForSCSSVar();
        })
    }

    ngOnDestroy(): void {
        this.widthsSubscription.unsubscribe();

    }

    /**
     * Sets new Position and Width When Scrolling.
     * @param data Type NavigationItemData
     */
    register(data: NavigationMenuItemData) {
        const newWidths = this.widths.value
        newWidths[data.position] = data.width;
        this.widths.next(newWidths)
    }

    /**
     * set ScrollContainer Width according to Screen Width.
     */
    setWidthForSCSSVar(){
        this.screenHeight = window.innerHeight;
        this.screenWidth = window.innerWidth;
        const width = this.screenWidth-this.offset-200;
        // - 350 offset after adding Demand tab
        this.element.nativeElement.style.setProperty('--width', width + 'px');
    }

    /**
     * Scroll To Left Functionality.
     */
    scrollLeft(){
        this.scrollAbleMenu.nativeElement.scrollBy({top:0, left:-100, behavior : "smooth"});
    }

    /**
     * Scroll to Right Functionality.
     */
    scrollRight(){
        this.scrollAbleMenu.nativeElement.scrollBy({top:0, left:100, behavior : "smooth"});
    }




}
