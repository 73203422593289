import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserinfoService } from './services/userinfo.service';
import { HttpClient } from '@angular/common/http';



@NgModule({
  declarations: [],
  imports: [
    CommonModule,
  ],
  providers:[UserinfoService]
})
export class CoreModule { }