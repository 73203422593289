import { Component, OnInit } from '@angular/core';
import { NavParams, PopoverController } from '@ionic/angular';
import { DemandService } from 'src/app/feature-modules/feature-shared/service/demand.service';

/**
 * Popover Component
 */
@Component({
  selector: 'app-popover',
  templateUrl: './popover.component.html',
  styleUrls: ['./popover.component.scss'],
})
export class PopoverComponent implements OnInit {
  popupVisible:boolean = false;
  stockNote: any;
  icon: any;

  /**
   * 
   * @param popover PopOverController
   * @param navParams Navigation Prameter
   */
  constructor(private popover:PopoverController, public navParams:NavParams, private demandService:DemandService) {}
  
    ngOnInit() {
    }

    /**
     * Close Popover By Dismissing Popovercontroller.
     */
    closePopover()
    {
      this.popover.dismiss();
    }

    /**
     * Saves Note For OutOfStock Items.
     */
    saveNote(){
      this.navParams.data.stockNote = this.stockNote;
      this.navParams.data.out_of_stock = true;
      this.navParams.data.invisible = true;
      this.popover.dismiss(this.navParams.data);  
    }

    /**
     * Sets Item in Stock and Dismiss Popover.
     */
    itemInStock(){
      this.navParams.data.out_of_stock = false;
      this.navParams.data.invisible = true;
      this.popover.dismiss(this.navParams.data);  
    }

    /**
     * Sets Item in Stock
     */
    itemNotInStock(){
      this.popupVisible = true;
    }
}
