import { Pipe, PipeTransform } from '@angular/core';

/**
 * Pipe Returns ValueArray From Objects(i.e. FormGroup).
 */
@Pipe({
  name: 'valueArray',
})
export class ValueArrayPipe implements PipeTransform {
  disable: boolean = true;
  transform(objects : any = []) {
    Object.values(objects).forEach(x => {
      if(x != null && x != ""){
        this.disable = false
      }
    })
    return this.disable
  }
}