import { UntypedFormGroup } from '@angular/forms';

import { Component, Inject, OnInit, Optional, ViewChild } from '@angular/core';
import { InventoryInputElement } from 'src/app/shared/models/inventory';
import { MatStepper } from '@angular/material/stepper';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { Router } from '@angular/router';
import { ModalController, NavParams } from '@ionic/angular';
import { DropdownInput } from '../../feature-shared/components/dynamic-forms/dynamic-inventory-input/input-dropdown';
import { TextboxInput } from '../../feature-shared/components/dynamic-forms/dynamic-inventory-input/input-text';
import { SchemaService } from '../../feature-shared/service/schema.service';
import { InventoryService } from '../../feature-shared/service/inventory.service';
import { VehicleService } from '../../feature-shared/service/vehicle.service';
import { PlatformService } from 'src/app/shared/services/platform.service';
import { InventoryInputControlService } from '../../feature-shared/service/input-inventory-control.service';
import { NumberInput } from '../../feature-shared/components/dynamic-forms/dynamic-inventory-input/input-number';
import { AssociateVehicleComponent } from '../../feature-shared/components/associate-vehicle/associate-vehicle.component';


/**
 * Create/Edit Inventory Component.
 */
@Component({
    selector: 'app-create-inventory',
    templateUrl: './create-inventory.component.html',
    styleUrls: ['./create-inventory.component.scss'],
})
export class CreateInventoryComponent implements OnInit {
    @ViewChild('stepper')
    stepper: MatStepper;
    form: UntypedFormGroup;
    formStep2: UntypedFormGroup;
    formStep3: UntypedFormGroup;
    waitingOnSavedFinished: boolean = true;
    @ViewChild(AssociateVehicleComponent)
    associateVehicleInventory: AssociateVehicleComponent;
    errorOnSave: boolean = false;
    inventoryInputElements: InventoryInputElement[];
    isEditable = true;
    inventoryInputs: (DropdownInput | TextboxInput | NumberInput)[];
    inventoryInputsFirst;
    inventoryInputsSecond;
    inventory: any;
    parentDialogName;
    isEditInventory: boolean;
    isDesktop: boolean;

    stepThreeCompleted = false;

    /**
    * setup Dependency Injections
    */
    constructor(private schemaService: SchemaService, private ics: InventoryInputControlService, private inventoryService: InventoryService,@Optional() public dialogRef: MatDialogRef<CreateInventoryComponent>,
        @Optional() @Inject(MAT_DIALOG_DATA) data, private router: Router, private vehicleService: VehicleService, private platformService: PlatformService,
        private modalController: ModalController, @Optional() private navParams: NavParams) {

        this.isDesktop = this.platformService.isDesktop();
        this.inventory = data?.inventory;
        this.isEditInventory = (data?.inventory) ? true : false;
        if(!this.isDesktop && navParams.data != null){
            const paramData = this.navParams.data;
            this.inventory = paramData?.inventory;
            this.isEditInventory = (paramData?.inventory) ? true : false;
        }
        this.schemaService.getInventorySchema().subscribe((result) => {
            // manage Dynymic-Form validation, sort, filter
            this.inventoryInputElements = result.data;
            const inventoryInputs = this.inventoryService.dynamicForm(this.inventoryInputElements);
            this.inventoryInputs = inventoryInputs.filter((element) => { return element != undefined });
            this.inventoryInputs.sort((a, b) => Number(b.required) - Number(a.required))
            this.inventoryInputsFirst = this.inventoryInputs.slice(0, 6);
            this.inventoryInputsSecond = this.inventoryInputs.slice(7);
            this.form = this.ics.toFormGroup(this.inventoryInputsFirst);
            this.formStep2 = this.ics.toFormGroup(this.inventoryInputsSecond);
            if (this.inventory) {
                this.form.patchValue(this.inventory);
                this.formStep2.patchValue(this.inventory);
            }
        });
    }
    
    /**
     * Lifecycle Method Oninit:
     * 
     * Setting dialog Name According To Action it is Performed.
     */
    ngOnInit() {
        // dialogName for success or fail step
        if ('/inventory' == this.router.url) {
            this.parentDialogName = this.router.url.slice(1);
        } else if (this.router.url.includes('/inventory/')) {
            this.vehicleService.getVehicleNameById(this.router.url.replace('/inventory/', '')).then(result => {
                this.parentDialogName = result
            })
        }
    }

    /**
     * Close Create/Edit Inventory dialog
     */
    closeDialog(){
        this.dialogRef.close()
    }
    
    /**
     * Navigate back to TotalInventory View.
     */
    goBackToInventory(){
        this.router.navigate(['/inventory']);
        this.isDesktop ? this.dialogRef.close(): this.dismissModal();
    }

    /**
     * Lifecycle Method AfterViewInit: Respond after Angular initializes the component's views and child views.
     * 
     * Impliments Selectionchange method From matstepper.
     */
    ngAfterViewInit() {
        setTimeout(() => {
            this.stepper.selectionChange.subscribe(step => {
                if (step.selectedIndex == 2 && this.waitingOnSavedFinished == true) { this.onSubmit()}
            });
            this.stepper._steps.changes.subscribe();
        });
    }

    /**
    * Calls dismiss method from ModalController.
    */
    dismissModal() {
        this.modalController.dismiss({
            'dismissed': true,
            'inventory': this.inventory
        });
    }

    /**
    * Check whether Edit Or Create Inventory.
    * 
    * Subscribe to updateInventory for Updating Existing Inventory.
    * 
    * Subscribe to PostInventory when New Inventory is Created.
    */
    onSubmit() {
        this.errorOnSave = false;
        this.waitingOnSavedFinished = true
        if(this.inventory){
            const inventory = {};
            for (const item in this.inventory) {
                inventory[item] = this.form.value[item] ? this.form.value[item] : this.formStep2.value[item] ? this.formStep2.value[item] : this.inventory[item]
            }
            this.inventoryService.updateInventory(inventory).then((resolve) => {
                this.inventory = resolve;
                this.waitingOnSavedFinished = false;
                this.stepThreeCompleted = true;
                !this.isDesktop ? this.dismissModal() : null;
            }).catch(() => {
                this.errorOnSave = true;
                this.waitingOnSavedFinished = false;
            })
        }
        else{
            const inventory = { ...this.form.value, ...this.formStep2.value }
            this.isEditable = false;
              this.inventoryService.postInventory(inventory).then((resolve)=> 
                {
                    this.inventory = resolve.data;
                    this.waitingOnSavedFinished = false;
                    this.stepThreeCompleted = true;
                    this.stepper.next();
                }).catch(() => {
                    this.errorOnSave = true;
                    this.waitingOnSavedFinished = false;
                })    
        }
    }

    /**
    * Assign Inventory to Vehicle At the Time of Updating and Creating Inventory.
    */
    executeAssociateVehicleInventory() {
        if(this.inventory){
            this.associateVehicleInventory.output(this.inventory.uid);
        }
       // this.dismissModal()
    }

}
