import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GeneralCardComponent } from './components/general-card/general-card.component';
import { VehicleCardComponent } from './components/vehicle-card/vehicle-card.component';
import { MaterialModule } from 'src/app/shared/material/material.module';
import { VehicleService } from './service/vehicle.service';
import { GroupByPipe } from './pipe/group-by.pipe';
import { VehicleSortByPipe } from './pipe/vehicle-sort-by.pipe';
import { ExtractVehiclenameOfModelPipe } from './pipe/extract-vehiclename-of-model.pipe';
import { SearchFilterPipe } from './pipe/search-filter.pipe';
import { InventoryService } from './service/inventory.service';
import { SchemaService } from './service/schema.service';
import { StateService } from './service/state.service';
import { IonicModule } from '@ionic/angular';
import { DynamicInventoryInputComponent } from './components/dynamic-forms/dynamic-inventory-input/dynamic-inventory-input.component';
import { InventoryInputControlService } from './service/input-inventory-control.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from 'src/app/shared/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { HorizontalNavigationMenuComponent } from './components/navigationFeature/horizontal-navigation-menu/horizontal-navigation-menu.component';
import { NavigationMenuItemComponent } from './components/navigationFeature/navigation-menu-item/navigation-menu-item.component';
import { VehicleScrollContainerComponent } from './components/navigationFeature/vehicle-scroll-container/vehicle-scroll-container.component';
import { DragDropDirective } from './directives/drag-drop.directive';
import { UploadService } from './service/upload.service';
import { AssociateVehicleComponent } from './components/associate-vehicle/associate-vehicle.component';
import { AssociateVehicleInputComponent } from './components/associate-vehicle-input/associate-vehicle-input.component';
import { DemandService } from './service/demand.service';
import { AllMessagesComponent } from './components/notifications/all-messages/all-messages.component';
import { MessageComponent } from './components/notifications/messages/message/message.component';
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';
import { MessageService } from './service/message.service';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { MessagesComponent } from './components/notifications/messages/messages.component';
import { ValueArrayPipe } from './pipe/value-array.pipe';



@NgModule({
  declarations: [
    GroupByPipe,
    ExtractVehiclenameOfModelPipe,
    VehicleSortByPipe,
    SearchFilterPipe,
    GeneralCardComponent,
    ValueArrayPipe,
    VehicleCardComponent,
    DynamicInventoryInputComponent,
    HorizontalNavigationMenuComponent,
    NavigationMenuItemComponent,
    VehicleScrollContainerComponent,
    DragDropDirective,
    AssociateVehicleComponent,
    AssociateVehicleInputComponent,
    AllMessagesComponent, 
    MessageComponent,
    MessagesComponent,
    ConfirmDialogComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    ReactiveFormsModule,
    FormsModule,
    IonicModule,
    TranslateModule,
    SharedModule,
    ScrollingModule,
  ],
  providers:[InventoryInputControlService],
  exports:[
    GroupByPipe,
    ExtractVehiclenameOfModelPipe,
    VehicleSortByPipe,
    ValueArrayPipe,
    SearchFilterPipe,
    GeneralCardComponent,
    VehicleCardComponent,
    DynamicInventoryInputComponent,
    HorizontalNavigationMenuComponent,
    NavigationMenuItemComponent, 
    VehicleScrollContainerComponent,
    DragDropDirective,
    AssociateVehicleComponent,
    AssociateVehicleInputComponent,
    ConfirmDialogComponent,
    MessageComponent,
    MessagesComponent
  ]
})
export class FeatureSharedModule { }
