import { SelectionModel } from '@angular/cdk/collections';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl, UntypedFormGroup } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { PopoverController } from '@ionic/angular';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { UserinfoService } from 'src/app/core/services/userinfo.service';
import { VehicleDemands } from 'src/app/shared/models/demands';
import { DemandService } from '../../feature-shared/service/demand.service';
import { VehicleService } from '../../feature-shared/service/vehicle.service';
import { PopoverComponent } from './popover/popover.component';

@Component({
  selector: 'app-demand-list',
  templateUrl: './demand-list.component.html',
  styleUrls: ['./demand-list.component.scss'],
})
export class DemandListComponent implements OnInit {
  @Input() form: UntypedFormGroup;
  @Input() mobile: boolean;

  displayedColumns: string[] = ["article_name", "article_nr", "open_demand", "quantity_unit"];
  displayedColumns2: string[] = ["article_name", "article_nr", "open_demand", "quantity_unit", "delete", "done", "note"];
  dataSource: MatTableDataSource<any>;
  selection = new SelectionModel<VehicleDemands>(true, []);

  demandList$: Observable<any>;

  demandId: string;
  step: number;
  role: string;
  createdAt: Date;
  createdBy: string;
  status: string;
  vehicleName: string;

  note: string = "";
  noteSelectVisible: boolean = false;
  stockNote: string;
  icon: string = "chat_bubble_outline";
  myDate = new Date();
  demands: any[];

  /**
   * Initalize neccessary values For Demandlist table.
   * @param popoverController ion-popover Handled Programmatically With controller for IonicAPP(Mobile).
   */
  constructor(private popoverController: PopoverController,
    private demandService: DemandService,
    private userInfoService: UserinfoService,
    private vehicleService: VehicleService) {

    this.dataSource = new MatTableDataSource([]);
    this.demandId = this.demandService.getSelectedDemand()?.uid;
    this.demandService.notes.subscribe((note) => (this.note = note));
    this.demandService.stockNotes.subscribe((note) => (this.stockNote = note));
    demandService.getDataToDisplay().subscribe(demands => {
      this.dataSource.data = demands;
      this.demands = demands;
    });
    this.demandList$ = demandService.getDemandList();
    if(this.demandId){
      this.demandService.getDemand(this.demandId).subscribe(demand => {
        this.status = demand.data["status"];
        const vehicleId = demand.data["vehicle"];
        this.vehicleService
          .getVehicleNameById(vehicleId)
          .then((id) => (this.vehicleName = id));
      });
    }
  }

  /**
   * Oninit it will set variables for Print File.
   */
  ngOnInit() {
    // printData
    this.demandList$.subscribe((demands) => {
      if(this.demandId){
        demands.data.filter((e) => e.uid == this.demandId).map((demandlist) => {
        this.createdAt = demandlist.created_at;
        this.createdBy = demandlist.assignee;
        });
      }
    });
    this.demandService.getCurrentStep().subscribe(step => this.step = step);
    this.userInfoService.data.subscribe(info => this.role = info.role);
   
  }

  /**
   * Username From userinfo Service as Observable which we Subscribe from Html using Async pipe.
   * @param userId Id of User who Created Print document.
   * @returns Observable<string>
   */
  getUserName(userId) {
    if (userId) {
      return this.userInfoService
        .getUserInfo(userId)
        .pipe(map((result: any) => result.username));
    }
  }

  /**
   * Display Columns if Given ColumnName is included.
   * @param columnName 
   * @param displayedColumns 
   */
  filterDisplayColumn(columnName, displayedColumns: any[]) {
    return displayedColumns.filter((col) =>
      col.includes(columnName) ? false : true
    );
  }

  /**
   * Hide columns Based on Role and Steps.
   * @param deleteCol Column For Delete Requirement.
   * @param doneCol Column for Picking Requirement.
   * @returns 
   */
  hideColumn(deleteCol, doneCol) {
    // show delete column only at step 2
    if (this.step == 3 || this.step == 1) {
      const deletecolumn = this.filterDisplayColumn(
        deleteCol,
        this.displayedColumns2
      );
      return this.filterDisplayColumn(doneCol, deletecolumn);
    } else if (this.step == 2) {
      if (this.role == "Picker") {
        return this.filterDisplayColumn(deleteCol, this.displayedColumns2);
      } else {
        return this.filterDisplayColumn(doneCol, this.displayedColumns2);
      }
    } else if (this.step == 4 || this.step == 5 || this.step == 6) {
      return this.filterDisplayColumn(deleteCol, this.displayedColumns2);
    } else {
      return this.displayedColumns2;
    }
  }

  /**
   * General Note For Demand Request.
   */
  setNote() {
    this.demandService.setNotes(this.note);
  }

  /**
   * Note For requirement if they are not in Stock.
   */
  setStockNote(){
    this.demandService.setStockNote(this.stockNote);
  }

  /**
   * This is called onClick action.
   * 
   * Set item OutOfStock with this Function.
   * @param row selected Row(Requirement Object.
   */
  itemNotInStock(row) {
    row.invisible = !row.invisible;
    row.out_of_stock = true;
    const savedDemandId = this.demandService.getSavedDemandId();
    savedDemandId ? this.demandId = savedDemandId : this.demandId;
    if(this.demandId){
      this.demandService.setOutofStockDemandEntry(row, this.demandId)
    }
    this.selection.deselect(row);
  }

  /**
   * This is called onClick action.
   * 
   * Set item Not OutOfStock with this Function.
   * @param row selected Row(Requirement Object.
   */
  itemInStock(row) {
    row.invisible = !row.invisible;
    row.out_of_stock = false;
    const savedDemandId = this.demandService.getSavedDemandId();
    savedDemandId ? this.demandId = savedDemandId : this.demandId;
    if(this.demandId){
      this.demandService.setOutofStockDemandEntry(row, this.demandId)
    }
    this.selection.select(row);
  }
  
  selectionToggle(row) {
    this.selection.toggle(row);
  }

  clickEvent(event, row) {
    event.stopPropagation();
    row.invisible = !row.invisible;
    row.out_of_stock = false;
  }

  // Open Demand Modal
  /**
   * Delete Demand in IonicApp(Mobile)
   * @param delid demandId that needs to be deleted.
   */
  deleteDemand(delid: string) {
    if (this.step != 1) {
      this.demandService.deleteDemand(delid);
    }
    else {
      this.form.get(delid).setValue(null)
    }
  }

  cancelOpenDemandInput(element: string) {
    document.querySelector("#" + element).classList.remove("is-active");
  }

  openDemandInput(element: string) {
    if([1,2].includes(this.step) ){
      document.querySelector("#" + element).classList.add("is-active");
    }
  }

  hideDemandInput(element: string) {
    document.querySelector("#" + element).classList.remove("is-active");
  }

  // Stock Popup
  /**
   * To cancel messagePopup.
   * @param row Selected Row(Requirement)
   */
  cancel(row) {
    row.popupVisible = !row.popupVisible;
    this.icon = "chat_bubble_outline";
  }

  /**
   * Save Stock Related Note
   * @param row Selected Row(Requirement)
   */
  saveNote(row) {
    row.stockNote = this.stockNote;
    this.icon = "chat";
    this.messagePopup(row);
  }

  /**
   * Message Popup show or Hide Function.
   * @param row Selected Row(Requirement)
   */
  messagePopup(row) {
    row.popupVisible = !row.popupVisible;
  }

  /**
   * It Opens Popover Component which Contains Picking Process for Demand in IonicApp(Mobile).
   * @param event Click Event on Image to Show Popover Component using PopoverController.
   * @param data Contains Selected Row Data.
   */
  async presentPopover(event, data) {
    if (this.step == 4) {
      const popover = await this.popoverController.create({
        component: PopoverComponent,
        componentProps: data,
        event: event,
        showBackdrop: false,
      });
      await popover.present();

      /**
       * On Popover Dismiss it will call SaveItem().
       */
      await popover.onDidDismiss().then((res) => {
        if (res.data?.out_of_stock) {
          this.saveItem(res);
          this.icon = "chat";
         } else {
          this.saveItem(res);
        }
      });
    }
  }

  /**
   * saves OutofStock DemandEntry.
   * @param res Response That Comes when Popover is dismissed.
   */
  saveItem(res) {
    const savedDemandId = this.demandService.getSavedDemandId();
    savedDemandId ? this.demandId = savedDemandId : this.demandId;
    if(this.demandId){
      this.demands.map((i) => {
        if (i.id == res.data?.id) {
          i.invisible = res.data?.invisible;
          i.stockNote = res.data?.stockNote;
          i.out_of_stock = res.data?.out_of_stock;
        }
      });
      this.demandService.setOutofStockDemandEntry(res.data, this.demandId)
    }      
    
  }

  cancelStockPopup(row) {
    row.popupVisible = !row.popupVisible;
  }

  /**
   * Used for Design Purpose in Mobile view.
   * @param item Contains Value of Selected row.
   * @returns  Image Path as String
   */
  popOverImage(item?: any) {
    let result = !Object.values(this.form.value).every(o => o === null);
    if([1,2].includes(this.step) && result) {
      return "/assets/icons/blue/ballotimagegrey.png";
    } 
    else if (this.step == 4  && !item.invisible) {
      return "/assets/icons/kommisioning-image.png";
    }
    else if (!item.out_of_stock) {
      if (this.step == 5 || this.step == 6) {
        return "/assets/icons/itemInStockGrey.png";
      } else if(this.step == 4){
        return "/assets/icons/item-in-stock.png";
      }
    } 
    else if (item.out_of_stock) {
      if (this.step == 5 || this.step == 6) {
        return "/assets/icons/itemNotInStockGrey.png";
      } else if(this.step == 4){
        return "/assets/icons/item-not-in-stock.png";
      }
    }
  }

}
