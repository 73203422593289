import { Router } from '@angular/router';
import { Component, Input, OnInit } from '@angular/core';
import { PlatformService } from 'src/app/shared/services/platform.service';
import { InventoryService } from '../../service/inventory.service';
import { DemandService } from '../../service/demand.service';

/**
 * Vehicle Card Component.
 */
@Component({
  selector: 'app-vehicle-card',
  templateUrl: './vehicle-card.component.html',
  styleUrls: ['./vehicle-card.component.scss'],
})
export class VehicleCardComponent implements OnInit {
  @Input() vehicleName:string;
  @Input() vehicleId:string;
  @Input() inventoryCount:number;
  @Input() requirementsCount:number;

  isDesktop:boolean;
  _notificationCount:string;
  constructor(private router: Router, private platformService: PlatformService,private inventoryService: InventoryService, private demandService:DemandService) {
  }

  /**
   * Subscribes to getInventoriesVehicleInventory and set inventoryCount.
   * 
   * For DemandCount Subscribes to getDemandList method.
   */
  ngOnInit() {
    this.isDesktop = this.platformService.isDesktop();
    this.inventoryService.getInventoriesVehicleInventory(this.vehicleId).subscribe((inventories)=>{
      this.inventoryCount = inventories.data.length;
    })
    this.demandService.getDemandList().subscribe((demandLists)=>{
      this.requirementsCount = demandLists.data.filter((demand)=>{return this.vehicleId==demand.vehicle}).length;
    })
   }

  @Input('setNotificationCount') set notificationCount(count) {
    this._notificationCount = ""+count;
  }

  /**
   * Navigates to Vehicle Inventory Page with current vehicleId.
   */
  goToVehicleInventory(){
    this.router.navigate(['/inventory/'+this.vehicleId]);
  }

}
