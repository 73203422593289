import { Router } from '@angular/router';
import { Icons, Colors } from '../navigation-menu-item/navigation-menu-item.component';
import { Component, OnInit } from '@angular/core';
import { PlatformService } from 'src/app/shared/services/platform.service';
import { MessageService } from '../../../service/message.service';

/**
 * HorizonatalNavigation Menu Component in Total Inventory View.
 */
@Component({
    selector: 'app-horizontal-navigation-menu',
    templateUrl: './horizontal-navigation-menu.component.html',
    styleUrls: ['./horizontal-navigation-menu.component.scss'],
})
export class HorizontalNavigationMenuComponent implements OnInit {

    icons = Icons;
    colors = Colors;
    isDesktop:boolean;
    messageCounter = 0;

    /**
     * Subscribe to Message Service and Get MessageCount For Horizonal Navigation.
     */
    constructor(private router:Router, private platformService:PlatformService, private messageService:MessageService) {
        this.messageService.message$.subscribe(message => {
            if(message){
                this.messageCounter = message.filter(msg => msg.read === false).length;
            }
          });
    }

    ngOnInit() {
        this.isDesktop = this.platformService.isDesktop();
    }

    /**
     * Presentation Logic for showing 99+ when Count is more than 99.
     */
    getMessageCount(){
        return this.messageCounter <= 99 ? this.messageCounter : '99+'
    }    
}
