import { BreakpointObserver } from '@angular/cdk/layout';
import { StepperOrientation } from '@angular/cdk/stepper';
import { AfterViewInit, Component, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { UserinfoService } from 'src/app/core/services/userinfo.service';
import { Vehicles } from 'src/app/shared/models/vehicles';
import { PlatformService } from 'src/app/shared/services/platform.service';
import { ConfirmDialogComponent, ConfirmDialogModel } from '../feature-shared/components/confirm-dialog/confirm-dialog.component';
import { DemandService } from '../feature-shared/service/demand.service';
import { InventoryService } from '../feature-shared/service/inventory.service';
import { MessageCategory, MessageIcon, MessageType } from '../feature-shared/service/message.service';
import { VehicleService } from '../feature-shared/service/vehicle.service';

/**
 * Demand Component
 */
@Component({
  selector: 'app-demand',
  templateUrl: './demand.component.html',
  styleUrls: ['./demand.component.scss'],
})
export class DemandComponent implements OnInit, AfterViewInit {
  @ViewChild('stepper')
  stepper: MatStepper;

  stepperOrientation: Observable<StepperOrientation>;
  vehicles$: Observable<any>;

  firstFormGroup: UntypedFormGroup;
  controlGroup:any = {};
  demandId: any;
  vehicles: any[];
  selectedValue: Vehicles[] = [];
  vehicleName: string;
  centralStorage: string;
  inventoriesLoaded: boolean = false;
  step1: boolean = true;
  isEditable = false;
  status: string;
  role:string;
  isDesktop: boolean;
  routeState: any;
  demandList$: Observable<any>;

  /**
   * Setup all Dependency
   * @param breakpointObserver  Responsive Stepper Utility
   * @param translate Translation Service Refrence
   * @param dialog MatDialog Refrence
   * @param router 
   */
  constructor(breakpointObserver: BreakpointObserver, 
    private vehicleService: VehicleService, 
    private platformService: PlatformService,
    private userInfoService: UserinfoService,
    private demandService: DemandService,
    private inventoryService: InventoryService,
    private translate:TranslateService,
    public dialog: MatDialog,
    private router:Router) {
      this.isDesktop = this.platformService.isDesktop();
    /**
     * Gets an observable of results for the given queries that will emit new results for any changes
     * in matching of the given queries.
     * @param value One or more media queries to check.
     * @returns A stream of matches for the given queries.
     */
      this.stepperOrientation = breakpointObserver.observe('(min-width: 800px)')
        .pipe(map(({ matches }) => matches ? 'horizontal' : 'vertical'));
      this.vehicles$ = this.vehicleService.getVehicles();
      const formctrl = new UntypedFormControl();
      this.firstFormGroup = new UntypedFormGroup({ 0: formctrl });
      this.demandList$ = demandService.getDemandList();
      this.demandId = this.demandService.getSelectedDemand()?.uid;
  }

  /**
   * Oninit Assign Vehicleid.
   * 
   * get vehicleNamebyId and assign the vehicleName variable.
   * 
   * LoadInventories method is called.
   */
  ngOnInit() {
    if(this.selectedValue.length <= 0){
      const vehicleId = this.demandService.getSelectedDemand()?.vehicle;
      this.vehicleService.getVehicleNameById(vehicleId).then((id) => {
        setTimeout(() => {
          this.vehicleName = id.toString()})
        });
        if(!this.vehicleName){
          this.centralStorage = this.translate.instant('myInventory.allDemand.central_storage')
        }
    }
    this.vehicles$.subscribe(vehicle => this.vehicles = vehicle.data);
    this.userInfoService.data.subscribe(info => this.role = info.role);
    if(this.routeState?.vehicle){
      // create demand from vehicleInventory view.
      this.demandService.setVehicleId(this.routeState?.vehicle);
      this.loadInventories();
      this.demandService.setCurrentStep(1);
    }
  }

  ngAfterViewInit(){
    setTimeout(() => {
      this.demandService.getCurrentStep().pipe(first()).subscribe(step =>{
        this.step1 = step < 2 ;
        this.stepper.selectedIndex = step;
      });
    });
  }

  /**
   * Call DemandService Method getStatusText.
   * @param status 
   * @returns string with full Text insted of Character.
   */
  getStatusText(status){
    return this.demandService.getStatusText(status);
  }

  /**
   * When MatStepper Step is changed this Method will be called.
   * @param event selectionChangeEvent
   * When demandId exist it will get a saved Status from Service.
   */
  onSelectionChange(event: any){
    this.demandService.setCurrentStep(event.selectedIndex);
    const savedDemandId = this.demandService.getSavedDemandId();
    savedDemandId ? this.demandId = savedDemandId : this.demandId;
    if(this.demandId)
    {
     this.demandService.getDemand(this.demandId).subscribe(demand => this.status = demand.data['status'])
    }
  }

  /**
   * Vehicle Selection Process Of Demand stepper (LagerOrt wählen)
   * @param event MatSelect selectionChangeEvent
   */
  selectedVehicle(event) {
    if (event.value?.vehicle_id) {
      const vehicleId = this.vehicles.map(id => { return id.vehicle_id }).filter(id => id == event.value.vehicle_id);
      this.demandService.setVehicle(vehicleId);
    }
    else {
      this.demandService.removeVehicle();
    }
  }

  /**
   * Updates Status of Demand According to Process Sone in Stepper.
   * @param status Status String Value(O,I,P,C)
   */
  statusUpdate(status:string){
    if(this.demandId){
      if(status=='I'){
        this.demandService.addAssignee(status, this.demandId).subscribe(res => {
          this.status = status;
        });
      }
      else if(status == 'C'){
        this.demandService.changeStatus(status, this.demandId).subscribe(res => {
          this.translate.get('myInventory.messages.status_C.successText').subscribe(result =>{
            if(this.vehicleName){
              this.inventoryService.successMessage(this.vehicleName, MessageIcon.demand,result,MessageType.success,MessageCategory.demand)
            }
            else{
              this.inventoryService.successMessage(this.translate.instant('myInventory.allDemand.central_storage'), MessageIcon.demand,result,MessageType.success,MessageCategory.demand)
            }
          });
        });
        this.status = status;
      }
      else{
        this.demandService.updateOutofStock(this.demandId, status).subscribe(response=> {
          this.demandService.outOfStockDemandEntries = []
          this.demandService.changeEntryStatus("P", this.demandId);
          this.demandService.changeStatus(status, this.demandId).subscribe(res => {
          this.status = res.data.status;
          this.translate.get('myInventory.messages.status_P.successText').subscribe(result =>{
            this.vehicleService.getVehicleNameById(response.data.vehicle).then((id) => {
              this.vehicleName = id.toString();
              if(this.vehicleName){
                this.inventoryService.successMessage(this.vehicleName, MessageIcon.demand,result,MessageType.success,MessageCategory.demand);
              }
              else{
                this.inventoryService.successMessage(this.translate.instant('myInventory.allDemand.central_storage'), MessageIcon.demand,result,MessageType.success,MessageCategory.demand);
              }
            })  
          })
          });
        });
      }
    }
  }
 
  /**
   * Canceling Demand Creation Process Method with Confirmation dialog.
   */
  cancel() {
    if(this.demandService.getCurrentStep().value == 2){
      this.translate.get('myInventory.demandDlg.cancelMessage.part1').subscribe(result => {
        this.translate.get('myInventory.demandDlg.cancelMessage.part2').subscribe(result1 => {
        const dialogData = new ConfirmDialogModel("Confirm Action", result + " " + result1);
        const dialogRef = this.dialog.open(ConfirmDialogComponent, {restoreFocus: true, data: dialogData , disableClose: true});
        dialogRef.afterClosed().subscribe(dialogResult => {
          if(dialogResult){
            this.resetState();
          }
        });  
        });
      });
    }
    else{
      this.resetState();
    }
  }

  /**
   * Reset stepper and all Observables In case of Cancelation
   */
  resetState(){
    this.demandService.resetDemands();
    this.vehicleName = "";
    this.selectedValue.length = 0;
    this.router.navigate(['/home']);
    this.stepper.reset();
    this.demandService.opendemand.next([])
    this.firstFormGroup.reset();
  }

  /**
   * Load centeral inventories as result and create FormGroup With Values.
   */
  loadInventories(){
    this.demandService.loadInventories().subscribe(inv=> {
      inv.data.forEach((inventory) => {
        this.controlGroup[inventory.uid] = new UntypedFormControl()
      });
      this.firstFormGroup = new UntypedFormGroup(this.controlGroup);
      this.inventoriesLoaded = true
    });
  }

  /**
   * Loads OutOfStock inventories for selected Demand.
   * @param demand Current Demand Object
   */
  loadOutofStockInventories(demand){
    this.demandService.loadOutofStockInventories().subscribe(inv=> {
      inv.data.forEach((inventory) => {
        this.controlGroup[inventory.uid] = new UntypedFormControl()
        demand.data.entries.map(demandEntry=>{
          if(inventory.uid === demandEntry.inventory && demandEntry.out_of_stock){
            this.firstFormGroup = new UntypedFormGroup(this.controlGroup);
            this.firstFormGroup.controls[inventory.uid].patchValue(demandEntry.quantity)
          }
        });
      this.inventoriesLoaded = true
      })
    })
  }

  /**
   * Create Another demand Request.
   */
  goToStepOne(){
    this.inventoriesLoaded = false;
    this.step1 = true;
    const newformctrl = new UntypedFormControl();
    this.firstFormGroup = new UntypedFormGroup({0:newformctrl});
    this.stepper.reset();
    this.demandService.resetDemands();
  }

  /**
   * Change step With Selected Requirements added to FormGroup.
   * 
   * open_demand Value is assigned.
   */
  goToStepTwo(){
    // got to demand checking 
    this.step1 = false;
    const keys = Object.keys(this.firstFormGroup.value).filter(key => {
      if( this.firstFormGroup.value[key] > 0){
        return true;
      }
    })
    // extract open_demand from inventory and pass it to next step
    this.demandService.setOpenDemands(this.demandService.demands.value.filter(inventory => {
      return keys.includes(inventory.id)
    }).map(id => {
      id['open_demand'] = this.firstFormGroup.value[id.id];
      return id;
    }));
  }

  /**
   * Navigates to AllDemand Page and Resets Value in Current View.
   */
  goToDemands(){
    this.router.navigate(['/alldemands']).then(() => {
      window.location.reload();
      this.demandService.resetDemands();
      this.selectedValue.length = 0;
      this.stepper.reset();
      this.firstFormGroup.reset();  
    })
  }

  /**
   * Go Back to step1 For Adding More requirments to the list.
   */
  addMoreDemand(){
    this.isEditable = true;
    this.step1 = true;
  }

  /**
   * Subscribe to Postemands Method in Service and Set SavedDemand Id From Response.
   * 
   * Show Message for Success Or Failure in Request.
   */
  sendDemands(){
    // post demand 
    const demands = this.demandService.getDemands().value;
    this.demandService.postDemands(demands).subscribe(response => {
      this.demandId = response.data.uid;
      this.status = response.data.status;
      this.demandService.setSavedDemandId(response.data.uid)
      this.translate.get('myInventory.messages.demandSend.successText').subscribe(result =>{
        this.vehicleService.getVehicleNameById(response.data.vehicle).then((id) => {
            this.vehicleName = id.toString()
            if(this.vehicleName){
              this.inventoryService.successMessage(this.vehicleName, MessageIcon.demand,result, MessageType.success,MessageCategory.demand)
            }
            else{
              this.inventoryService.successMessage(this.translate.instant('myInventory.allDemand.central_storage'), MessageIcon.demand,result, MessageType.success,MessageCategory.demand)
            }
        })
        });  
      this.firstFormGroup.reset();
    });
  }

  /**
   * With this Mehtod you can Create whole new Demand Request After you Close Previous One .
   * 
   * If Previous Demand have some OutOfStock Inventories then you can create new Demand with this Invnetories.
   */
  createNewDemand(){
    this.demandService.getDemand(this.demandId).subscribe(demand => {
      const outofstock = demand.data.entries.filter(entry=> entry.out_of_stock)
      if(outofstock.length <= 0){
        this.goToStepOne()
      }
      else{
        this.stepper.selectedIndex = 1;
        this.step1 = true;
        this.loadOutofStockInventories(demand);
      }
    })
  }

}
