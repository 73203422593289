import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, from, Observable } from 'rxjs';
import { catchError, concatMap, first, map, shareReplay, take, tap } from 'rxjs/operators';
import { AuthHttpService } from 'src/app/auth/auth-http.service';
import { UserinfoService } from 'src/app/core/services/userinfo.service';
import { environment } from 'src/environments/environment';
import { InventoryService } from './inventory.service';

/**
 * Demand Service
 */
@Injectable({
  providedIn: 'root'
})
export class DemandService {
  step: BehaviorSubject<number> = new BehaviorSubject(0);
  demands: BehaviorSubject<any[]> = new BehaviorSubject([]);
  opendemand: BehaviorSubject<any[]> = new BehaviorSubject([]);
  allDemands: BehaviorSubject<any[]> = new BehaviorSubject([]);
  donedemand: BehaviorSubject<any[]> = new BehaviorSubject([]);
  notes: BehaviorSubject<string> = new BehaviorSubject("");
  stockNotes: BehaviorSubject<string> = new BehaviorSubject("");
  outOfStockDemandEntries: any[] = [];

  demandListObserver: Observable<any>;
  demandData: Observable<any>;
  inventories$: Observable<any>;

  backendURL = environment.backendUrlRoot + "api/";

  inventories: any[] = [];
  vehicleId: string;
  role: string;
  orgId: string;
  userId: string;
  selectedDemand: any;
  savedDemandId: string;
  vehicleName: string;

  constructor(private userInfoService: UserinfoService, 
      private authhttp: AuthHttpService, 
      private inventoryService: InventoryService,
      private translate:TranslateService,
      private router: Router) {
    this.inventories$ = this.inventoryService.getCentralInventory()
    this.userInfoService.data?.subscribe((info) => {
      this.userId = info.userId;
      this.role = info.role;
    });
   }

  // Get Functions

  /**
   * Fetch All Demands From Database
   * @returns DemandList Observable
   */
  getDemandList() {
    return this.userInfoService.getCurrentUserInfo().pipe(
      concatMap((result: any[]) => {
        if (!this.demandData) {
          const response = this.authhttp.interceptHttpRequest(this.backendURL + result['orgId'] + '/demand', 'GET');
          this.demandData = from(response).pipe()
        }
        return this.demandData;
      }))
  }

  /**
   * Loads Inventories.
   * @returns Observable
   */
  loadInventories() {
    return this.inventories$.pipe(
      map((result) => {
        this.inventories = result.data;
        this.setDemands(this.inventories);
        return result;
      })
    );
  }

  /**
   * Load All OutofStock Inventories.
   * @returns Observable
   */
  loadOutofStockInventories(){
    return this.inventories$.pipe(
      map((result) => {
        return result;
      })
    );
  }

  /**
   * Return Demands as Subject.
   * @returns BehaviourSubject
   */
  getDemands() {
    return this.demands;
  }

  /**
   * @returns DemandID String
   */
  getSavedDemandId() {
    return this.savedDemandId;
  }

  /**
   * @returns Step Number in BehaviourSubject
   */
  getCurrentStep() {
    return this.step;
  }

  /**
   * @returns Selected Demand
   */
  getSelectedDemand() {
    return this.selectedDemand;
  }

/*   getDataToDisplayWithId(demandID){
    if(typeof(demandID) == 'object'){
      return this.getDemand(demandID["uid"]).pipe(
        map(demand => {
          return this.getDataToDisplay();
        }),
        first()
      );
    }
    else{ 
      return this.getDemand(demandID).pipe(
        map(demand => {
          return this.getDataToDisplay();
        }),
        first()
      );
    }
  }
 */

  /**
   * Returns Full Text For Design.
   * @param quantity Qauntity As Character
   * @returns String
   */
  getQuantityText(quantity: string){
    if(quantity == 'Q'){
      return this.translate.instant("myInventory.data.quantity");
    }
    else if(quantity == 'M'){
      return this.translate.instant("myInventory.data.meters");
    } 
    else if(quantity == 'L'){
      return this.translate.instant("myInventory.data.liters");
    }
    else if(quantity == 'K'){
      return  this.translate.instant("myInventory.data.kilograms");
    }
  }

  /**
   * @param status Demand Progress Status
   * @returns string with full Text insted of Character
   */
  getStatusText(status){
    if(status == 'O'){
      return this.translate.instant('myInventory.allDemand.demandStatus.open');
    }
    else if(status == 'I'){
      return this.translate.instant('myInventory.allDemand.demandStatus.inProgress');
    }
    else if(status == 'P'){
      return this.translate.instant('myInventory.allDemand.demandStatus.Picked');
    }
    else if(status == 'C'){
      return this.translate.instant('myInventory.allDemand.demandStatus.Closed');
    }
  }

  /**
   * Returns Demands Data According to Step and Role.
   * @returns Array of Demands as BehaviourSubject
   */
  getDataToDisplay() {
    if(this.role == 'User'){
      if (this.step.value == 1 || this.step.value == 0) {
        return this.demands;
      }
      else if (this.step.value == 2 || this.step.value == 3) {
        return this.opendemand;
      }
      else if (this.step.value == 4) {
        return this.donedemand;
      }
    } 
    else if(this.role == 'Manager' || 'Picker'){
      if (this.step.value == 1 || this.step.value == 0) {
        return this.demands;
      }
      else if (this.step.value == 2 || this.step.value == 3 || this.step.value == 4 ||
        this.step.value == 5 ) {
        return this.opendemand;
      }
      else if(this.step.value == 6){
        return this.donedemand;
      } 
    }
  }

  // Set Functions

  /**
   * Set CurrentStep to given Value.
   * @param step step Provided
   */
  setCurrentStep(step: number) {
    this.step.next(step);
  }

  /**
   * Set vehicleId to local variable.
   * @param vehicles Array of Vehicle
   */
  setVehicle(vehicles) {
    vehicles.map((vehicleId) => {
      this.vehicleId = vehicleId;
      return vehicleId;
    });
  }

  /**
   * Handles Vehicle id Coming from Routestate.
   * @param vehicleId VehicleId from Routestate.
   */
  setVehicleId(vehicleId) {
    this.vehicleId = vehicleId;
  }

  /**
   * Set note for Demand Request
   * @param note Demand Note
   */
  setNotes(note) {
    this.notes.next(note);
  }

  /**
   * Set OutofStock Note For Demand
   * @param note Demand Outofstock Note
   */
  setStockNote(note){
    this.stockNotes.next(note);
  }

  /**
   * Save DemandId For Used in nextsteps after Demand is saved.
   * @param demandId Response data uid After Postdemands.
   */
  setSavedDemandId(demandId){
    this.savedDemandId = demandId;
  }

  /**
   * Save OutofStock Row Entries To Create New demand with the information.
   * @param row Selected Inventory Row.
   * @param demandId demandId for Selected Row.
   */
  setOutofStockDemandEntry(row: any, demandId?: any){
    if(demandId){
      this.getDemand(demandId).subscribe(demand => {
        demand.data.entries.map(entry => {
          if(entry.inventory == row.id){
            entry.out_of_stock = row.out_of_stock;
            this.outOfStockDemandEntries.push(entry)
          }
        })
      });
     }
  }
  
  /**
   * Map Demand array with Given Inventories.
   * @param inventories 
   */
  setDemands(inventories) {
    //this.inventory = inventories;
    inventories?.map((id) => {
      const new_demands = this.demands.value;
      new_demands.push({
        id: id["uid"],
        article_name: id["name"],
        article_nr: id["common_identifier"],
        quantity_unit: this.getQuantityText(id["unit"]),
        open_demand: id["open_demand"],
        done_demand: id["done_demand"],
        notes: id["notes"],
      });
      this.demands.next(new_demands);
    });
  }

  /** 
   * save SelectedDemand From All Demand View.
  */
  setSelectedDemand(demand) {
    this.selectedDemand = demand;
  }

  /**
   * Set Open Demands
   * @param openDemands OpenDemand Array
   */
  setOpenDemands(openDemands: any[]) {
    this.opendemand.next(openDemands);
  }

  /**
   * Set Done Demands
   * @param doneDemands DoneDemand Array
   */
  setDoneDemands(doneDemands: any[]){
     this.donedemand.next(doneDemands);
  }

  // Remove functions

  /**
   * Delete demands From Open_demand Observable.
   * @param delid demandId
   * @returns Void
   */
  deleteDemand(delid: string) {
    const filterDemands = this.opendemand.value.filter(
      (row) => row.id != delid
    );
    return this.opendemand.next(filterDemands);
  }

  /**
   * Reset VehicleId
   */
  removeVehicle() {
    this.vehicleId = "";
  }

  /**
   * Reset all BehaviourSubject Value.
   */
  resetDemands() {
    this.donedemand.next([]);
    this.opendemand.next([]);
    this.demands.next([]);
    this.step.next(0);
  }

  // Api Request

  /**
   * Prepare DemandBody For Post Request.
   * @param demands demands Data
   * @returns Entries of Inventory assign to demand Request.
   */
  createDemandBody(demands) {
    return demands
      .filter((demand) => demand.open_demand > 0)
      .map((id) => {
        return {
          inventory: id.id,
          inventory_element: [],
          quantity: id.open_demand,
        };
      });
  }

  /**
   * Create Data Array For Demand.
   * @param demand demands Array
   * @returns 
   */
  createDemandRequest(demand) {
    return {
      organisation_id: this.orgId,
      vehicle: this.vehicleId,
      entries: this.createDemandBody(demand),
      notes: demand.notes,
    };
  }

  /**
   * Post Request For Demand
   * @param demand demand Object
   * @returns Observable HttpResponse
   */
  postDemands(demand){
    return this.userInfoService.getCurrentUserInfo().pipe(
      concatMap((result) => {
        this.orgId = result['orgId'];
        const url = this.backendURL + this.orgId + '/demand';
        const response = this.authhttp.interceptHttpRequest(url, 'POST', this.createDemandRequest(demand))
        return response;
    }),take(1)
    /* ,tap(() => {
      this.inventoryService.successMessage(this.vehicleName, MessageIcon.demand, "Bedarf abgesendet", MessageType.success,MessageCategory.demand)})
    , catchError(error => {
      this.inventoryService.errorMessage('Error', MessageIcon.demand, "myInventory.messages.demand " + JSON.stringify(error), MessageType.error,MessageCategory.demand);
      throw new Error(error)
    }) */
    )
  } 

  /**
   * Updates Status of Inventory Entry in Demand Request.
   * @param status Status of Invnetory Entries
   * @param demandId 
   * if entry Outofstock then status should be open "O" Else "P"
   */
  changeEntryStatus(status: string, demandId:any) {
    if(demandId){
      this.getDemand(demandId).subscribe(demand => {
        demand.data.entries.map(demandEntry=>{
          // if entry Outofstock then status should be open "O"
          this.userInfoService.getCurrentUserInfo().pipe(
            concatMap((orgId) => {
            const url = this.backendURL + orgId["orgId"] + "/demand-entry/" + demandEntry.uid + "/status";
            const response =  this.authhttp.interceptHttpRequest(url, 'PUT', { status: status })
            return from(response).pipe(
              shareReplay(1))
            })
          ).subscribe();
        })
      })
    }
  }

  /**
   * Update Status of Demand Request.
   * @param status Demand status
   * @param demandId DemandId
   * @returns Observable<HttpResponse>
   */
  changeStatus(status: string, demandId:any) {
    const updateData = this.userInfoService.getCurrentUserInfo().pipe(
      concatMap((orgId) => {
        const url =this.backendURL + orgId["orgId"] + "/demand/" + demandId + "/status";
        const response = this.authhttp.interceptHttpRequest(url, 'PUT', { status: status })
        return from(response)
      }),shareReplay(1))
        take(1)
    return updateData;
  }

  /**
   * Assignee will be added To Demand when Commisioner or Manager Starts Kommisioning Process.
   * @param status Updated Status Of Demand (P= picked)
   * @param demandId 
   * @returns Observable<HttpResponse>
   */
  addAssignee(status: string, demandId:any){
    return this.userInfoService.getCurrentUserInfo().pipe(
      concatMap((orgId)=>{
        const url = this.backendURL + orgId['orgId'] + '/demand/' + demandId;
        return this.authhttp.interceptHttpRequest(url, 'PATCH', { status: status, assignee: orgId['userId']});
      }),
      take(1)
    )
  }

  /**
   * Updates Outof Stock DemandEntries.
   * @param demandId 
   * @returns 
   */
  updateOutofStock(demandId:any, status){
    return this.userInfoService.getCurrentUserInfo().pipe(
       concatMap((orgId)=>{
         const url = this.backendURL + orgId['orgId'] + '/demand/' + demandId;
         return this.authhttp.interceptHttpRequest(url, 'PATCH', { entries: this.outOfStockDemandEntries});
       })
    )
  }

  /**
   * It reads demand and Map new Array According to Demand Status and Returns demand Data.
   * 
   * If Status is P then demand is Commisioned.
   * 
   * If Status is O then demadn is still Open.
   * @param demand 
   */
  readDemands(demand: any){
    if(demand){
      this.getDemand(demand.uid).subscribe(response => {
            this.donedemand.next([]);
            this.opendemand.next([]);
            response.data.entries.map(entry => {
              if(entry.status == "P"){
                const recieveDoneDemand = [];
                this.inventories$.subscribe(inv => {
                  inv.data.forEach(element => {
                    response.data["entries"].map(entry => {
                      if (entry.inventory == element.uid) {
                        element["open_demand"] = entry.quantity + "";
                        element["done_demand"] = element["uid"];
                        recieveDoneDemand.push({
                          id: element["uid"],
                          article_name: element["name"],
                          article_nr: element["common_identifier"],
                          open_demand: element["open_demand"],
                          done_demand: element["done_demand"],
                          quantity_unit: this.getQuantityText(element["unit"]),
                          notes: element["notes"],
                          invisible: element["invisible"],
                          out_of_stock: entry["out_of_stock"],
                        });
                      }
                    })
                  });
                this.donedemand.next(recieveDoneDemand);
                })
              }
              else if(entry.status == "O"){
                const receivedOpenDemand = [];
                this.inventories$.subscribe(inv => {
                  inv.data.forEach(element => {
                    response.data["entries"].map(entry => {
                      if (entry.inventory == element.uid) {
                        element["open_demand"] = entry.quantity + "";
                        receivedOpenDemand.push({
                          id: element["uid"],
                          article_name: element["name"],
                          article_nr: element["common_identifier"],
                          open_demand: element["open_demand"],
                          invisible: element["invisible"],
                          done_demand: element["done_demand"],      
                          quantity_unit: this.getQuantityText(element["unit"]),
                          notes: element["notes"],
                        });
                      }
                    })
                  })
                  this.opendemand.next(receivedOpenDemand);
                })
              }
            })
      return response.data
    })
    }
  }

  /**
   * Returns Given demand id data.
   * @param demand demandId
   * @returns Observable<HttpResponse>
   */
  getDemand(demand) {
    // for Bedarf anlegen needs demand["uid"]
    // for Selected Demand it need object of demand
    return this.userInfoService.getCurrentUserInfo().pipe(
      concatMap((orgId) => {
        const response = this.authhttp.interceptHttpRequest(
          this.backendURL + orgId["orgId"] + "/demand/" + demand, 'GET')
        return from(response)
      })  
    );
  }


  // VehicleDemandList

  /**
   * Function Determines if Current User has Access for Updating Demand Request.
   * @param demand Demand Object
   * @returns 
   */
  canAccessDemand(demand){
    if(demand['status'] == 'O'){
      if( this.role == 'User' && demand['created_by'] != this.userId ){
        return true;
      }
    }
    else if(demand['status'] == 'I'){
      if(this.role == 'Manager' || demand['assignee'] == this.userId){
        return false;
      }
      else if(demand['created_by'] != this.userId){
        return true;
      }
    }
    else if(demand['status'] == 'P'){
      if(demand['created_by'] != this.userId && this.role != 'Manager'){
          return true;
      }
    }
  }

  /**
   * Navigates to Demand View and Load Data According to User Role and Demand Status.
   * @param demand demandId
   */
  goToAddDemand(demand){
    this.setSelectedDemand(demand);
    this.readDemands(demand)
    if(this.role == 'Manager'){
      if(demand.status == 'O'){
        this.setCurrentStep(3)} 
      else if(demand.status == 'P'){
        this.setCurrentStep(6)}
      else if(demand.status == 'I'){
        this.setCurrentStep(4)}
    }
    else if(this.role == 'User'){
      if(demand.status == 'O'){this.setCurrentStep(3)}
      else if(demand.status == 'I'){this.setCurrentStep(4)}
      else if(demand.status == 'P'){
        this.setCurrentStep(4)}
    }
    else if(this.role == 'Picker'){
      if(demand.status == 'O'){this.setCurrentStep(3)}
      else if(demand.status == 'I'){this.setCurrentStep(4)}
      else if(demand.status == 'P'){
        this.setCurrentStep(5)}
    }
    else if(demand.status == 'C'){
      this.setCurrentStep(6);
    }
    this.loadInventories().subscribe(id =>
      this.router.navigate(['/demands'], {
        state: {
          origin:this.router.url,
          demand: demand.uid
        }
      })
    );
  }

  /**
   * Returns Opendemand Behaviour Subject Value
   * @returns Opendemand Value
   */
  getOpenDemands() {
    return this.opendemand.value
  }

}
