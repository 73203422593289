import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, NgZone, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSelectionList } from '@angular/material/list';
import { IonInfiniteScroll, IonItemSliding } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { map, pairwise, throttleTime } from 'rxjs/operators';
import { UserinfoService } from 'src/app/core/services/userinfo.service';
import { PlatformService } from 'src/app/shared/services/platform.service';
import { MessageService } from '../../../service/message.service';
import { ConfirmDialogComponent, ConfirmDialogModel } from '../../confirm-dialog/confirm-dialog.component';

/**
 * AllMessages Component.
 */
@Component({
  selector: 'app-all-messages',
  templateUrl: './all-messages.component.html',
  styleUrls: ['./all-messages.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class AllMessagesComponent implements OnInit, AfterViewInit{
  messages: any[] = [];
  selectedOptions: any[] = [];
  allChecked: any;
  readStatus:string = " ";
  isDesktop:boolean;
  items = [];
  checkedOption:boolean = false;
  editMode: boolean = false;
  isIndeterminate:boolean;
  masterCheck:boolean = false;

  @ViewChild('message')  allSelected: MatSelectionList;
  @ViewChild('scroller') scroller: CdkVirtualScrollViewport;
  @ViewChild('IonInfiniteScroll') infiniteScroll: IonInfiniteScroll;


  /**
   * 
   * @param ngZone  this service is to optimize performance when starting a work consisting of one or more asynchronous tasks.
   * @param changeDetectorRef Initiate change-detection
   */
  constructor(public service: MessageService, private ngZone:NgZone, public translate: TranslateService,
    public dialog: MatDialog, private userInfoService:UserinfoService, private platformService:PlatformService,private changeDetectorRef: ChangeDetectorRef) {
    this.service.getAllMessages().subscribe(message => {
      this.messages = message.data.results;
    });

    for (let i = 0; i < 30; i++) {
      this.items.push( this.items.length );
      }
   }

  ngOnInit() {
    this.isDesktop = this.platformService.isDesktop();
  }

  /**
   * Edit Messages(i.e. Delete , Read, UnRead)
   */
  editMessage() {
    this.editMode = true;
  }

  /**
   * Cancel EditMode
   */
  cancelEdit(){
    this.editMode = false;
  }

  /**
   * Disable the Buttons When No message is Selected.
   */
  isDisabled(){
    if(this.allChecked || this.selectedOptions.length > 0){
      return false;
    }
    else{
      return true ;
    }
  }

  /**
   * CheckBox Mark if Message is Selected.
   * @param event IONChange Event
   */
  checkSelection(event: any){
    this.checkedOption = event.detail.checked
  }

  /**
   * Delete Button Disabled attribute.
   * @returns Boolean
   */
  checkedOptions(){
  return this.checkedOption
  }

  /**
   * Select All Messages.
   */
  checkMaster() {
    setTimeout(()=>{
      this.messages.forEach(obj => {
        obj.isChecked = !obj.isChecked;
      });
    });
    this.masterCheck = !this.masterCheck;
  }

  /**
   * Handle Selection Change
   * @param options 
   */
  onSelectChange(options){
    this.selectedOptions = options.selectedOptions.selected.map(item => item.value);
  }

  /**
   * Toggle CheckBox Selection.
   * @param event CheckBox Change Event
   */
  toggleSelection(event) {
    event.checked ? this.allSelected.selectAll() : this.allSelected.deselectAll();
  }

  /**
   * Username From userinfo Service as Observable which we Subscribe from Html using Async pipe.
   * @param userId
   * @returns Observable<string>
   */
  getUserName(userId){
    if(userId){
      return this.userInfoService.getUserInfo(userId).pipe(map(result => result.username));
    }
  }

  /**
   * Subscribe to getMessagesNextPage() in Service for More Messages From Server.
   */
  getNextMessages(){
    this.service.getAllMessages().subscribe(message => {
      if(message.data.next !== null)
        {
          this.service.getMessagesNextPage(message.data.next).subscribe(res =>
            {
              this.service.message$.next(this.messages)
            });
        }
      });
  }
  
  /**
   * Fetch More Messages on Scroll.
   * 
   * NgZone is used here to work with asynchronous task of Loading Messages.
   * 
   * After oad ChangeDetection is Neccesary to Get Effect on Html View.
   */
  ngAfterViewInit(){
    setTimeout(() => {
      if(this.isDesktop){
        this.scroller.elementScrolled().pipe(
          map(e => this.scroller.getRenderedRange().end == this.scroller.getDataLength()),
          pairwise(),
          throttleTime(400)
        ).subscribe(() => {
          this.ngZone.run(() => {
         this.getNextMessages()
          });
        });
      }
    });
    this.changeDetectorRef.detectChanges();
   }

  /**
  * Updates Read Status Of Message.
  * @param msg Message Object
  * @param slidingItem It is Optional Param as it is only Used In Mobile App.
  */
  markAsRead(msg?:any, slidingItem? :IonItemSliding){
    if(!this.isDesktop){
      this.updateReadStatus(msg.uid, true);
      slidingItem.close()
    }
    else{
      let messages;
      if(this.allChecked){
        messages = this.allSelected._value
        messages.map(message=> {
          if(message.read === false){
            this.updateReadStatus(message.uid, true);
            this.allSelected.deselectAll();
            this.allChecked = false;
          }
        })
      }
      else if(this.selectedOptions.length > 0){
        messages = this.selectedOptions
        messages.map(message=> {
          if(message.read === false){
            this.updateReadStatus(message.uid, true);
            this.selectedOptions = []
            this.allSelected.deselectAll();
          }
        })
      }
    }
  }

  /**
  * Updates ReadStatus of Message.
  * @param msg Message Object
  * @param slidingItem It is Optional Param as it is only Used In Mobile App.
  */
  markAsUnread(msg?: any, slidingItem? :IonItemSliding){
    if(!this.isDesktop){
      this.updateReadStatus(msg.uid, false);
      slidingItem.close()
    }
    else{
      let messages;
      if(this.allChecked){
        messages = this.allSelected._value
        messages.map(message=> {
          if(message.read === true){
            this.updateReadStatus(message.uid, false);
            this.allSelected.deselectAll();
            this.allChecked = false
          }
        })
      }
      else if(this.selectedOptions.length > 0){
        messages = this.selectedOptions
        messages.map(msg=> {
          if(msg.read === true){
            this.updateReadStatus(msg.uid, false);
            this.selectedOptions = [];
            this.allSelected.deselectAll();
          }
        })
      }
    }
  }

  /**
   * @param messageId Selected Message Id
   * @param readStatus ReadStatus (Read, Unread)
   */
  updateReadStatus(messageId, readStatus){
    this.service.updateMessage(messageId, readStatus).subscribe(res=> {
      let updateStatus = this.messages.find(m => m.uid == res.data.uid);
      let index = this.messages.indexOf(updateStatus);
      this.messages[index].read = readStatus;
      this.service.message$.next(this.messages)
    });
  }

  /**
   * Delete Message Method
   * 
   * Conformation dialog PopsUp When Try To Delete.
   * 
   * After Confirmed Dialog Closed More Messages are Loaded from Server.
   */
  deleteMessage() {
    let messages: any;
    if(this.isDesktop){
      if(this.allChecked){
        messages = this.allSelected._value;
      }
      else if(this.selectedOptions.length > 0){
        messages = this.selectedOptions;
      }
      const confirmationMessage = this.translate.instant('myInventory.inventory.delete.confirm_message'); // myInventory.inventory.delete.confirm_message
      const dialogData = new ConfirmDialogModel("Confirm Action", confirmationMessage);
      const dialogRef = this.dialog.open(ConfirmDialogComponent, {restoreFocus: false, data: dialogData }); //myInventory.confirmDlg.title
      dialogRef.disableClose = true;
      dialogRef.afterClosed().subscribe(dialogResult => {
        if(dialogResult){
          messages.map(message=> {
            this.service.deleteMessage(message.uid).subscribe(() => {
              this.messages = this.messages.filter(item => item.uid != message.uid);
              this.service.message$.next(this.messages)
              this.getNextMessages();
            });
          })
        }
      });  
    }
    else{
      this.messages.forEach(message => {
        if(message.isChecked){
          this.service.deleteMessage(message.uid).subscribe(() => {
          this.messages = this.messages.filter(item => item.uid != message.uid);
          this.service.message$.next(this.messages)
          this.masterCheck = false
          this.editMode = false
          this.getNextMessages()
        });
        }
      });
    }
  }

  /**
   * Delete Function For Mobile.
   * @param msg Selected Message Object
   */
  deleteItem(msg){
    this.service.deleteMessage(msg.uid).subscribe(() => {
      this.messages = this.messages.filter(item => item.uid != msg.uid);
      this.service.message$.next(this.messages)
    });
  }

  /**
   * Load More Data From Server In Mobile App.
   * @param event ionInfinite event from ion-infinite-scroll in Mobile View.
   */
  loadData(event) {
    // Using settimeout to simulate api call 
    setTimeout(() => {
      // load more data
     this.getNextMessages();
      //Hide Infinite List Loader on Complete
      event.target.complete();
      //Rerender Virtual Scroll List After Adding New Data
      // App logic to determine if all data is loaded
      // and disable the infinite scroll
       if (this.items.length == 1000) {
        event.target.disabled = true;
      }
    }, 500);
  }

}
