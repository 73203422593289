import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NavController } from '@ionic/angular';
import { AuthActions, IAuthAction } from 'ionic-appauth';
import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { CustomAuthService } from 'src/app/auth/customauth.service';
import { UserinfoService } from 'src/app/core/services/userinfo.service';
import { PlatformService } from 'src/app/shared/services/platform.service';
import { DemandService } from '../feature-shared/service/demand.service';
import { MessageService } from '../feature-shared/service/message.service';
import { VehicleService } from '../feature-shared/service/vehicle.service';

/**
 * Home Component.
 */
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
/**
 * HomeComponent class
 */
export class HomeComponent implements OnInit {
  query: any = '';
  sortByParam: any = '';
  events$ = this.auth.events$;
  sub: Subscription;
  vehicles: any[] = [];
  action: IAuthAction;
  isDesktop: boolean;
  vehicles$: Observable<any>;
  requirementListCounter:string;
  demands$: Observable<any>;
  tempVehicleData: any[] = [];
  messageCounter: string;
  hideLoadButton: boolean = true;
  /**
   * setup Dependency Injections.
   */
  constructor(
    private router: Router,
    private vehicleService: VehicleService,
    private auth: CustomAuthService,
    private navCtrl: NavController,
    private userInfoService: UserinfoService,
    private platformService: PlatformService,
    private demandService: DemandService,
    private messageService: MessageService,
  ) {
    this.vehicles$ = this.vehicleService.getVehicles();
    this.demands$ = this.demandService.getDemandList();
  }
  
  /**
   * @ignore
   */
  public async refreshToken(): Promise<void> {
    await this.auth.refreshToken();
  }

  /**
   * Lifecycle Method OnInit:
   * 
   * Update requirementListCounter and MessageCounter.
   * 
   * Load Vehicles From Server.
   */
  ngOnInit() {
    this.demands$.subscribe((demandList) => {
      this.requirementListCounter = demandList.data.filter(id => id.status == 'O').length;
    });
    this.sub = this.auth.events$.subscribe((action) => {
      this.onAction(action); 
      this.onSignOutSuccess(action)
    });
    
    this.isDesktop = this.platformService.isDesktop();
    this.vehicleService.getVehicles().subscribe(vehicles => {
      this.vehicles = vehicles.data
      this.tempVehicleData = this.vehicles.slice(0,5);
    });
    this.messageService.getAllMessages().subscribe(message => {
      this.messageCounter = message.data.results.filter(msg => msg.read === false).length;
    });
  }

  onSearchTermChange() {
    if(this.query){
      this.hideLoadButton = false
      return this.vehicles.filter(item => item.vehicle_name.toLowerCase().includes(this.query.toLowerCase()));
    }
    else{
      this.hideLoadButton = true
      return this.tempVehicleData
    }
  }


  /**
   * This Method Loads 3 More Vehicle From Server On Click Event.
   */
  showMore() {
    let newLength = this.tempVehicleData.length + 3;
    if (newLength > this.vehicles.length) {
      newLength = this.vehicles.length
    }
    this.tempVehicleData = this.vehicles.slice(0, newLength);
  }
 
  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  /**
   * @ignore
   */
  goToTotalInventory() {
    this.router.navigate(['/inventory']);
  }

  /**
   * @ignore
   */
  onBlur(){
    this.query = '';
  }

  private onAction(action: IAuthAction) {
    if (
      action.action === AuthActions.LoadTokenFromStorageFailed ||
      action.action === AuthActions.SignInFailed ||
      action.action === AuthActions.SignOutSuccess
    ) {
      delete this.action;
    } else {
      this.action = action;
    }
  } 

  public async signOut() {
    await this.auth.signOut();
    this.auth.endSessionCallback();
  }

  onSignOutSuccess(action: IAuthAction) {
    if (action.action === AuthActions.SignOutSuccess) {
      this.navCtrl.navigateRoot('login');
    }
  }

  /**
   * This Method gets Username From Userinfo Service By Subscribing to "getCurrentUserInfo".
   */
  getUserName() {
    return this.userInfoService
      .getCurrentUserInfo()
      .pipe(map((result) => result.username));
  }

  /**
   * @ignore
   */
  allDemands(){
    this.router.navigate(['/alldemands']);
  }
  
  /**
   * @ignore
   */
  goToMessages(){
    this.router.navigate(['/messages']);
  }

  /**
   * Returns messageCount as String.
   */
  getMessageCount(){
    return this.messageCounter;
  }

}
