import { Injectable } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { InputBase } from '../components/dynamic-forms/dynamic-inventory-input/input-base';

/**
 * Inventory Input Control Service
 */
@Injectable()
export class InventoryInputControlService {
    constructor() { }

    /**
     * Add Given inventoryInputs to FormGroup.
     * @param inventoryInputs Data From Component
     * @returns FormGroup
     */
    toFormGroup(inventoryInputs: InputBase<string>[]) {
        const group: any = {};

        inventoryInputs.forEach(inventoryInput => {
            if(inventoryInput.required){
                group[inventoryInput.key] = new UntypedFormControl(inventoryInput.value || '', Validators.required)
            }
            else if(inventoryInput.type == 'number')
            {   
                group[inventoryInput.key] = new UntypedFormControl(inventoryInput.value || null);
            }
            else{
                group[inventoryInput.key] = new UntypedFormControl(inventoryInput.value || '');
            }
            /* group[inventoryInput.key] = inventoryInput.required ? new FormControl(inventoryInput.value || '', Validators.required)
                : new FormControl(inventoryInput.value || ''); */
        });
        return new UntypedFormGroup(group);
    }
}
