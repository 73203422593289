import { Pipe, PipeTransform } from '@angular/core';

/**
 * Pipe is used in HomePage to Sort vehicle with Alphabet and vehicle_id.
 */
@Pipe({
  name: 'sortBy'
})
export class VehicleSortByPipe implements PipeTransform {
  transform(items:any [], direction:string){
    if (direction === 'desc'){
      return this.sortDescending(items);

    }
    else if(direction === 'asc'){
      return this.sortAscending(items);
    }
    else if(direction === 'id'){
      return this.sortByID(items);
    }
    return items;
  }
    sortAscending(items){
      return [...items.sort((left, right): number => {
        if (left.vehicle_name < right.vehicle_name) return -1;
        if (left.vehicle_name > right.vehicle_name) return 1;
        })]
    }

    sortDescending(items){
      return [...items.sort((left, right): number => {
        if (left.vehicle_name < right.vehicle_name) return 1;
        if (left.vehicle_name > right.vehicle_name) return -1;
        })]
    }
    sortByID(items){
      return [...items.sort((left, right): number => {
        if (left.vehicle_id < right.vehicle_id) return -1;
        if (left.vehicle_id > right.vehicle_id) return 1;
        })]
     }

}