import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { UserinfoService } from 'src/app/core/services/userinfo.service';
import { DemandService } from 'src/app/feature-modules/feature-shared/service/demand.service';
import { PlatformService } from 'src/app/shared/services/platform.service';

/**
 * DemandDetailView Component
 */
@Component({
  selector: 'app-demand-detail-view',
  templateUrl: './demand-detail-view.component.html',
  styleUrls: ['./demand-detail-view.component.scss'],
})
export class DemandDetailViewComponent implements OnInit {
  demandList$:Observable<any>;
  vehicleDemandList: any[] = [];
  isDesktop: boolean;

  constructor(private demandService: DemandService,private userInfoService: UserinfoService, private platformService: PlatformService) { 
    this.demandList$ = this.demandService.getDemandList()
  }

  /**
   * Subscribe to demandist Observable and filter demandist with VehicleId.
   */
  ngOnInit() {
    this.isDesktop = this.platformService.isDesktop();
    this.demandList$.subscribe((demandList) => {
      if (history.state != null) {
        const state = history.state;
        this.vehicleDemandList = demandList.data.filter(
          (id) => state.vehicle == id["vehicle"]
        );
      }
    });
  }

   /**
   * Username From userinfo Service as Observable which we Subscribe from Html using Async pipe.
   * @param userId Id of User Handles Demand.
   * @returns Observable<string>
   */
  getUserName(userId) {
    if (userId) {
      return this.userInfoService
        .getUserInfo(userId)
        .pipe(map((result) => result.username));
    }
  }

   /**
   * Call DemandService Method getStatusText.
   * @param status 
   * @returns string with full Text insted of Character.
   */
  getStatusText(status) {
    if (status == "O") {
      return "Open";
    } else if (status == "I") {
      return "Inprogress";
    } else if (status == "P") {
      return "Picked";
    } else if (status == "C") {
      return "Closed";
    }
  }
  
  /**
  * Call to Function in Service which Navigates to Demand Component.
  */
  goToAddDemand(demand) {
    this.demandService.goToAddDemand(demand);
  }

  /**
   * Call to Function in Service which determines if The User has Access To Edit Demand.
   */
  canAccessDemand(demand) {
    this.demandService.canAccessDemand(demand);
  }

}
