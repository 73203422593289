import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

/**
* Confirm Dialog Component.
*/
@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss'],
})
export class ConfirmDialogComponent {
  title: string;
  message: string;

  /**
   * Inhjecting Matdialogdata token.
   */
  constructor(public dialogRef: MatDialogRef<ConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmDialogModel) {
    /** 
     * Update view with given values 
     */ 
    this.title = data.title;
    this.message = data.message;
  }
 
  /**
   * Close the dialog, return true.
   */
  onConfirm(){
    this.dialogRef.close(true);
  }

  /**
   * Close the dialog, return false
   */
  onDismiss(){
    this.dialogRef.close(false);
  }
}

/**
* ConfirmDialogModel Class is Customizable Confirmation Dialog.
*/
export class ConfirmDialogModel {

  /**
   * 
   * @param title Title of Confirmation Dialog
   * @param message Custom Message That you want to Provide
   */
  constructor(public title: string, public message: string) {
  }
}

