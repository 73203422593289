import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class UserinfoService {

    baseUrl = environment.baseUrl;
    data: Observable<any>;
    userInfoMap: UserInfoMap = {};
    constructor(private http: HttpClient) {

    }

    /**
     * getCurrentUserInfo Method returns Observable Containing all User Information(i.e. role,userid..).
     */
    getCurrentUserInfo() {
        if (!this.data) {
            this.data = this.http.get( this.baseUrl + 'de/sortimocommercewebservices/v2/auth/myInventory/userinfo').pipe(
                shareReplay(1)
            );
        }

        return this.data;

    }

    /**
     * getUserInfo Method is used in Demand component to get Username with userId as parameter.
     */
    getUserInfo(userId) {
        if (!this.userInfoMap[userId]) {
            this.userInfoMap[userId] = this.http.get( this.baseUrl + 'de/sortimocommercewebservices/v2/auth/myInventory/userinfo/' + userId).pipe(
                shareReplay(1)
            );
        }
        return this.userInfoMap[userId]
    }
}

type UserInfoMap = {
    [key: string]: Observable<any>;
}
