import { Injectable } from '@angular/core';
import { BehaviorSubject, from } from 'rxjs';
import { environment } from 'src/environments/environment';
import * as fileSaver from "file-saver";
import { UserinfoService } from 'src/app/core/services/userinfo.service';
import { catchError, concatMap, take, tap } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { unparse } from "papaparse";
import { UntypedFormBuilder } from '@angular/forms';
import * as XLSX from 'xlsx';
import { Router } from '@angular/router';
import { MessageCategory, MessageIcon, MessageType } from './message.service';
import { InventoryService } from './inventory.service';
import { TranslateService } from '@ngx-translate/core';

/**
 * Inventory Upload Service
 */
@Injectable({
  providedIn: 'root'
})
export class UploadService {
  step: BehaviorSubject<number> = new BehaviorSubject(0);
  file:File;
  inventories: any[] = [];
  backendURL = environment.backendUrlRoot + "api/";
  headers: HttpHeaders = new HttpHeaders();
  correctInventoryForm; FormGroup;


  /**
   * Create FormGroup and Inject Dependency
   */
  constructor(
    private router: Router,
    private formBuilder: UntypedFormBuilder,
    private userInfoService:UserinfoService,
    private translate:TranslateService,
    private inventoryService:InventoryService,
    private http:HttpClient) {
    this.correctInventoryForm = this.formBuilder.group({
      correctInventories: [''],
    });
   }

  /**
   * set Inventories in service So it can be shred with other Components.
   * @param inventories 
   */
  setInventory(inventories) {
    this.inventories = inventories
  }

  /**
   * CurrentStep infomartion
   * @returns BehaviourSubject Step Number
   */
  getCurrentStep(){
    return this.step;
  }

  /**
   * Updates File Detail in Service
   * @param file 
   */
  setFile(file){
    this.file = file;
  }

  /**
   * Gives File Detail. 
   * @returns File
   */
  getFile(){
    return this.file;
  }

  /**
   * Updates BehaviourSubject Variable Step
   * @param step Number
   */
  setCurrentStep(step:number){
    this.step.next(step);
  }

  /**
   * After Read File Completes check if Required field is Not Null.
   * @param inventories Parsed Inventory
   * @returns Array of Inventory
   */
  countErrors(inventories: any[]) {
    return inventories.filter(e => {
      return (e.classification ==null || e.unit == null || e.name == null)
    })
  }

  /**
   * Download Sample Inventory File in local Machine.
   */
  downloadTemplate() {
    this.downloadTemplateApi().subscribe((response) => {
      let blob: any = new Blob([response], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      });
      fileSaver.saveAs(blob, "InventoryTemplate");
    });
  }

  /**
   * Fetch File From Azure Storage
   * @returns Blob Observable
   */
  downloadTemplateApi() {
    const url = this.backendURL + "downloads/templates/inventory";
    return this.http.get(url, { responseType: "blob" });
  }

  /**
   * When There are no Errors in File Or When Only Correct Inventories are Uploading.
   * this Function is Used for unParsing the File data.
   * @param inventories Array of Inventory
   */
  unparseFile(inventories: any){
    const fileType = this.getFile().type;
    var file;
    // identify file type and change Url Accordingly
    if(fileType == "text/csv"){
      const correctData = unparse(inventories, {
        header:true,
        delimiter: ';',
      })
      file = new File([correctData], 'Inventory.csv', {type: "text/csv"}); 
    }
    else{
      const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
      const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(inventories);  
      const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] }; 
      const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
      const data: Blob = new Blob([excelBuffer]);
      file = new File([data], 'Inventory.xlsx', {type:EXCEL_TYPE})
    }
    this.correctInventoryForm.get("correctInventories").setValue(file)
    const formData = new FormData();
    formData.append("file", this.correctInventoryForm?.get("correctInventories").value);
    this.uploadInventory(formData, file.type).subscribe(res => this.router.navigate(['/inventory']));
  }

  /**
   * Uploads Inventory to Database When All inventories are Correct.
   * @param formData Inventories as FormData
   * @param fileType CSV/Excel
   * @returns Observable
   */
  uploadInventory(formData: any, fileType:string){
    let urlPostfix;
    // identify file type and change Url Accordingly
    fileType == "text/csv" ? urlPostfix = "/uploads/csv/inventory" : urlPostfix = "/uploads/excel/inventory" ;
    return this.userInfoService.getCurrentUserInfo().pipe(
      concatMap((result) => {
        const url = this.backendURL + result["orgId"] + urlPostfix;
          return this.http.post(url, formData, {
            reportProgress: true,
            observe: "events",
          });
      })
      ,take(1)
      ,tap(() => {
        this.translate.get('myInventory.messages.uploadInventorySuccess_Part1').subscribe(result =>{
        this.inventoryService.successMessage(this.file?.name, MessageIcon.uploadInventory, result , MessageType.success, MessageCategory.uploadInventory);
        })
      }),
      catchError(error => {
        this.translate.get('myInventory.messages.uploadInventoryFailure').subscribe((result)=>{
        this.inventoryService.errorMessage(this.file?.name, MessageIcon.uploadInventory, result + JSON.stringify(error), MessageType.error,MessageCategory.uploadInventory);
        })
        throw new Error(error)
      })
    )
  }
}