import { BreakpointObserver } from '@angular/cdk/layout';
import { StepperOrientation } from '@angular/cdk/stepper';
import { Component, DoCheck, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';
import { Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { UploadService } from '../../feature-shared/service/upload.service';
import { parse } from "papaparse";
import { SchemaService } from '../../feature-shared/service/schema.service';
import { InventoryInputElement } from 'src/app/shared/models/inventory';
import { InventoryInputControlService } from '../../feature-shared/service/input-inventory-control.service';
import { InventoryService } from '../../feature-shared/service/inventory.service';
import * as XLSX from 'xlsx';
import { Router } from '@angular/router';
import { MessageCategory, MessageIcon, MessageType } from '../../feature-shared/service/message.service';
import { TranslateService } from '@ngx-translate/core';

/**
 * Upload CSV/XlSX Inventory Component.
 */
@Component({
  selector: 'app-upload-inventory',
  templateUrl: './upload-inventory.component.html',
  styleUrls: ['./upload-inventory.component.scss'],
})
export class UploadInventoryComponent implements OnInit , DoCheck{
  stepperOrientation: Observable<StepperOrientation>;
  form: UntypedFormGroup;
  uploadForm: UntypedFormGroup;
  reader = new FileReader();
  progress = 0;
  @ViewChild("stepper") stepper: MatStepper;
  goToCorrectError: boolean = false;
  inventory: any[] = [];
  errorCount: any;
  correctEntries: number;
  correctInventory: any[] = [];
  allCorrect: boolean = false;
  confirm: boolean = false;
  tabs: any[] = [];
  selected = new UntypedFormControl(0);
  tabindex: number;
  corrected: boolean;
  submitInv: boolean = false;
  inventoryInputElements: InventoryInputElement[];
  inventoryInputs;
  newInventory: any[] = [];


  /**
   * setup Dependency Injections.
   */
  constructor(
    breakpointObserver: BreakpointObserver,
    private formBuilder: UntypedFormBuilder,
    private dialogRef: MatDialogRef<UploadInventoryComponent>,
    private uploadService: UploadService,
    private schemaService: SchemaService,
    private translate: TranslateService,
    private ics: InventoryInputControlService,
    private inventoryService: InventoryService,
    public router: Router
  ) {
    //responsive stepper Logic
    this.stepperOrientation = breakpointObserver
      .observe('(min-width: 800px)')
      .pipe(map(({ matches }) => (matches ? 'horizontal' : 'vertical')));
  }

  /**
   * Lifecycle Method OnInit:
   * 
   * Initializes the FormGroup.
   */
  ngOnInit() {
    this.uploadForm = this.formBuilder.group({
      inventories: [''],
    });
  }

  /**
   * Lifecycle Method DoCheck: Detect and act upon changes that Angular can't or won't detect on its own.
   */
  ngDoCheck() {
    if (this.errorCount?.length == this.tabs?.length) {
      this.form?.valueChanges.subscribe(a => {
        this.submitInv = true
      }
      )
    }
    this.form?.valid ? this.corrected = true : this.corrected = false
  }

  /**
   * @ignore
   * Presetation Logic
   */
  get errorText() {
    return  (this.errorCount.length - (this.tabindex + 1));
  }

  get fehlerCount() {
    return  (this.tabindex + 1) + this.translate.instant('myInventory.messages.uploadcorrectInventory.error_text.part3')+ this.errorCount.length;
  }

  get correctedEntries() {
    return (this.tabs.length) + "/" + (this.inventory.length - this.correctEntries);
  }

  get hideErrorText() {
    return this.errorCount?.length - (this.tabindex + 1) > 0
  }

  closeDialog(){
    this.dialogRef.close()
  }

  /**
   * Add tabs For Inventories with Error in Upload dialog
   */
  addTab() {
    if (this.form.dirty) {
      this.newInventory.push(this.form.value);
    }
    this.tabindex = this.tabindex++;
    if (this.tabs.length < this.errorCount.length) {
      this.tabs.push('');
      this.selected.setValue(this.tabs.length - 1);
      this.tabindex = this.selected.value;
      const inventory = this.errorCount[this.tabindex];
      if (inventory) { this.form?.patchValue(inventory); }
    }
  }

  onSelectionChange(event: any) {
    this.uploadService.setCurrentStep(event.selectedIndex);
  }

  /**
   * Reads File When DragandDrop Event.
   */
  onFiledropped(event){
    if (event.length > 1) {
      this.translate.get('myInventory.messages.uploadInventoryFailure.only1fileAllowed').subscribe((result)=>{
        this.inventoryService.errorMessage('Error', MessageIcon.uploadInventory, result , MessageType.error,MessageCategory.uploadInventory);
        })
      this.dialogRef.close();
    }
    else{
      if (event.length > 0) {
        const file: File = event.item(0);
        this.readFiles(file)
      }
    }
  }

  /**
   * Reads File When Browse from dialog.
   */
  onFileChange(event) {
    if (event.files.length > 1) {
      this.translate.get('myInventory.messages.uploadInventoryFailure.only1fileAllowed').subscribe((result)=>{
        this.inventoryService.errorMessage('Error', MessageIcon.uploadInventory, result , MessageType.error,MessageCategory.uploadInventory);
        })
      this.dialogRef.close();
    } else {
      if (event.files && event.files.length > 0) {
        const file: File = event.files.item(0);
        this.readFiles(file);
      }
    }
  }

  /**
   * Reads File and Check the Type of File.
   * 
   * If file is not .csv or .xlsx Then Throw an Error.
   */
  readFiles(file){
    this.uploadService.setFile(file);
    // set value in formData
    this.uploadForm.get("inventories").setValue(file)
    console.log(file.type)
    if (file.type == "text/csv") {
      this.readCsvFile(file);
    }
    else if (file.type == "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
      this.readXlsxFile(file)
    }
    else {
      this.translate.get('myInventory.messages.uploadInventoryFailure.incorrectFile').subscribe((result)=>{
        this.inventoryService.errorMessage(file.name, MessageIcon.uploadInventory, result , MessageType.error,MessageCategory.uploadInventory);
        })    
      this.dialogRef.close();
    }
  }

  /**
   * Reading .xlsx File and Passing data To Parser Function.
   * 
   * called FileProgress method for Progressbar value and changes a Step when File Reading is complete.
   */
  readXlsxFile(file) {
    this.reader.readAsBinaryString(file);
    // OnLoad
    this.reader.onload = (event) => {
      this.fileProgress(event);
      const data = this.reader.result;
      this.parseXlsxFile(data);
      this.stepper.selected.completed = true
      this.stepper.next();
    }
    // ONLoadEnd
    this.reader.onloadend = () => {
      this.onLoadEnd()
    }
  }

  /**
   * Reading .csv File and Passing data To Parser Function.
   * called FileProgress method for Progressbar value and changes a Step when File Reading is complete.
   */
  readCsvFile(file) {
    this.reader.readAsText(file, "utf-8");
    // onLoad
    this.reader.onload = (e) => {
      this.fileProgress(e);
      const csvData = this.reader.result as string;
      this.parseCsvFile(csvData);
      this.stepper.selected.completed = true
      this.stepper.next();
    }
    //onLoadEnd
    this.reader.onloadend = () => {
      this.onLoadEnd();
    }
  }

  /**
   * Parse .xlsx File Content using [XSLX-Parser Library]{@link https://www.npmjs.com/package/xlsx-parser}.
   */
  parseXlsxFile(data) {
    const workBook = XLSX.read(data, { type: 'binary' })
    const jsonData = workBook.SheetNames.reduce((initial, name) => {
      const sheet = workBook.Sheets[name];
      initial[name] = XLSX.utils.sheet_to_json(sheet, { defval: null });
      return initial;
    }, {});
    this.inventory = jsonData[Object.keys(jsonData)[0]];
    this.inventoryCorrection(this.inventory)
  }

  /**
   * Parse .csv File Content using [papaparse Library]{@link https://www.papaparse.com/}.
   */
  parseCsvFile(csvData) {
    parse(csvData, {
      header: true,
      encoding: "utf-8",
      delimiter: ";",
      dynamicTyping: true, complete: (result) => {
        if (result.meta.fields.length >= 11 && result.meta.fields.length <= 12 && result.meta.delimiter == ";") {
          this.inventory = result.data;
          this.inventoryCorrection(result.data)
        } else {
          var file = this.uploadService.getFile();
          this.translate.get('myInventory.messages.uploadInventoryFailure.incorrectFile').subscribe((result)=>{
            this.inventoryService.errorMessage(file.name, MessageIcon.uploadInventory, result , MessageType.error,MessageCategory.uploadInventory);
            })    
          this.dialogRef.close();
        }
      },
    });
  }

  /**
   * Detects Incorrect Inventory Entry in file.
   */
  inventoryCorrection(result) {
    this.uploadService.setInventory(result);
    this.errorCount = this.uploadService.countErrors(this.inventory);
    this.correctEntries = this.inventory?.length - this.errorCount?.length;
    this.correctInventory = this.inventory?.filter(e => {
      return (e.classification != null && e.unit != null && e.name != null && e.common_identifier != null)
    })
  }

  /**
   * FileReader Event:
   * 
   * @example
   * 
   *  onloadend: ((this: FileReader, ev: ProgressEvent<FileReader>) => any) | null;
   * 
   * Updated CurrentStep after Progress is 100%.
   */
  onLoadEnd() {
    if (this.progress == 100) {
      this.allCorrect = true ? this.errorCount?.length <= 0 : this.allCorrect = false;
      this.stepper.selected.completed = true
      this.uploadService.setCurrentStep(2);
      this.uploadService.getCurrentStep().pipe(first()).subscribe(step => {
        this.stepper.selectedIndex = step;
      });
    }
  }

  fileProgress(event) {
    this.progress = Math.round((event.loaded / event.total) * 100);
  }

  /**
   * Call to Service Method downloadTemplate.
   */
  downloadFile() {
    this.uploadService.downloadTemplate();
  }

  /**
   * @ignore
   * Continues The upload and do not Confirm Cancellation.
   */
  countinueUpload() {
    this.confirm = false;
  }

  /**
   * Confirm Cancellation
   */
  cancelCorrection() {
    if (this.tabs.length < this.errorCount.length) {
      this.goToCorrectError = false;
      this.removeTab(this.tabindex)
    }
    else if (this.errorCount.length == this.tabs.length) {
      this.confirm = true;
    }
    else {
      this.dialogRef.close()
    }
  }

  removeTab(index: number) {
    this.tabs.splice(index, 1);
  }

  /**
   * Open Dynamic Form for Editing Incorrect value in Inventory.
   */
  editInventory() {
    this.schemaService.getInventorySchema().subscribe((result) => {
      this.inventoryInputElements = result.data;
      const inventoryInputs = this.inventoryService.dynamicForm(this.inventoryInputElements);
      this.inventoryInputs = inventoryInputs.filter((element) => { return element != undefined; });
      this.inventoryInputs.sort((a, b) => Number(b.required) - Number(a.required));
      this.form = this.ics.toFormGroup(this.inventoryInputs);
      this.form.markAllAsTouched();
      this.goToCorrectError = true;
      this.addTab();
    });
  }

  // Data Submission

  /**
   * Uploads Only Correct Inventories Without Error.
   */
  uploadOnlyCorrectInventory(){
   this.uploadService.unparseFile(this.correctInventory);
    this.dialogRef.close();
  }

  submit() {
    this.newInventory.push(this.form?.value);
    const newInventory = this.newInventory?.map(inv => {
      return {
        uid: null,
        parent_id: null,
        name: inv?.name,
        common_identifier: inv?.common_identifier,
        classification: inv?.classification,
        ean: inv?.ean,
        target_amount: inv?.target_amount,
        unit: inv?.unit,
        standard_supplier: inv?.standard_supplier,
        supplier_sku: inv?.supplier_sku,
        price_retail: inv?.price_retail,
        //price_value: inv?.price_value,
        description: inv?.description,
        //supplier_article_number: inv?.supplier_article_number
      }
    })
    const allInventory = newInventory.concat(this.correctInventory)
    this.uploadService.unparseFile(allInventory);
    this.dialogRef.close();
  }

  /**
   * Uploads Inventories when All Inventory entries are Correct.
   */
  uploadInventory() {
    if (this.allCorrect) {
      const fileType = this.uploadService.getFile().type;
      const formData = new FormData();
      formData.append("file", this.uploadForm.get("inventories").value);
      this.uploadService.uploadInventory(formData, fileType).subscribe(res =>this.router.navigate(['/inventory']))
    }
  }

}
