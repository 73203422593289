import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { InventoryService } from '../../feature-shared/service/inventory.service';
import { CreateInventoryComponent } from '../create-inventory/create-inventory.component';
import { AssignInventoryComponent } from '../assign-inventory/assign-inventory.component';

/**
 * Inventory Detail Component.
 */
@Component({
  selector: 'app-inventory-detail',
  templateUrl: './inventory-detail.component.html',
  styleUrls: ['./inventory-detail.component.scss'],
})
export class InventoryDetailComponent implements OnInit {

  inventory: any;

   /**
   * Gets Inventory Object From RouterNavigation State
   * setup Dependency Injection.
   */
  constructor(private router:Router, public modalController: ModalController, private inventoryService: InventoryService) {
    if (this.router.getCurrentNavigation()?.extras.state) {
      const state = this.router.getCurrentNavigation().extras.state;
      this.inventory = state.inventory
    }

   }

  ngOnInit() {
  }

  /**
   * an async Operation with deleteInventory method from inventoryService
   */
  deleteItem(){
    this.inventoryService.deleteInventory(this.inventory).then((resolve) => {
      this.router.navigate(['/inventory']);
     });
  }

  /**
   * Create Modal for Edit Inventory Component and Presents it
   * Perform Actions with onDidDismiss method
   */
  async editInventory() {
      const modal = await this.modalController.create({
          component: CreateInventoryComponent,
          componentProps: {
              inventory : this.inventory
            }
        });

      await modal.present();
      await modal.onDidDismiss().then(resolve => {
        const inv = resolve.data.inventory
        this.inventory = inv;
    });
  }

  /**
   * Create Modal for Assign Inventory Function and Presents it
   */
  async assignInventory() {
    const modal = await this.modalController.create({
        component: AssignInventoryComponent,
        componentProps: {
          inventory : this.inventory,
        }
      });
    await modal.present();
  }

}
