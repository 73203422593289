import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class PlatformService {
    constructor(private platform:Platform){
    }

    /**
    * Function Detects Platform using ionic Platform class.
    */
    isDesktop(){
        return this.platform.is("desktop");
    }

}
