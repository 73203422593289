/**
 * Enviornment variable for Testsystem
 */
export const environment = {
    production: false,
    local: false,
    test: true,
    baseUrl:"https://www.sortimo-test.",
    oidcConfig: {
      client_id: 'myInventory',
      server_host: 'https://www.sortimo-test.de/authorizationserver',
      client_secret: 'Jy1MglbOGJxNEKdZOm8R',
      //redirect_url: 'http://localhost:4200/auth_callback',
      redirect_url: 'app://de.mysortimo.myinventory',
      end_session_redirect_url: 'http://localhost:4200/auth_endsession',
      scopes: 'basic openid email profile',
      pkce: true,
    },
    backendUrlRoot: "https://inventory-server.sortimo-test.de/my-inventory/",
    imagepath: "https://inventory-server.sortimo-test.de",
};
