import { BehaviorSubject, from, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse} from '@angular/common/http';
import { concatMap, shareReplay, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { UserinfoService } from 'src/app/core/services/userinfo.service';
import { AuthHttpService } from 'src/app/auth/auth-http.service';
import { data } from 'autoprefixer';

/**
 * Message Handling Service
 */
@Injectable({
  providedIn: 'root'
})
export class MessageService {
  messages: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  message$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  messagesData: any[] = [];
  backendURL = environment.backendUrlRoot + 'api/';
  headers: HttpHeaders = new HttpHeaders();
  updateData: Observable<any>;
  allMessages: {} = {};
  userId: any;

  /**
   * Subsribe to Userinfo Service and Get UserId
   */
  constructor(private authhttp: AuthHttpService,
              private userInfoService: UserinfoService) {
    userInfoService.getCurrentUserInfo().subscribe(user => this.userId = user.userId);
  }

  /**
   * Creates a New Message with Following Params and Save it.
   * @param name Name of Object(inventoryname , vehicalname etc..)
   * @param icon Icon Action Performed
   * @param message Message Content
   * @param message_type Type of Message (i.e. Success , Error)
   * @param category Message Action Category
   */
  newMessage(name: string, icon: MessageIcon, message: string, message_type: MessageType, category: MessageCategory) {
    this.messagesData.push({ name, icon, message, isRead: false, message_type, category});
    this.messages.next(this.messagesData);
    this.messagesData.map(id => {
      this.allMessages = {
        category: id.category,
        icon_name: id.icon,
        title: id.name,
        message: id.message,
        navigation_url: '/login',
        target_group: 'Manager',
        user_id: this.userId,
        message_type: message_type
      };
    });
    this.saveMessage(this.allMessages).subscribe();
  }


  /**
   * set Observable Value.
   * @param value new Message Value
   */
  setAllMessage(value: any){
    this.message$.next(value)
  }

  /* Api Request */

  /**
   * Fetch All Messages from Database. 
   * @returns Observable of HttpResponse.
   */
  getAllMessages() {
    return this.userInfoService.getCurrentUserInfo().pipe(
      concatMap((orgId) => {
        const url = this.backendURL + orgId.orgId + '/' + orgId.userId + '/notification';
        const response = this.authhttp.interceptHttpRequest(url, 'GET');
        return from(response).pipe(tap(res => {
          if(!!res.data) {
            this.message$.next(res.data.results)
          }
        }))
      })
    );
  }

  /**
   * Loads More Messages From Database.
   * @param url URl For NextPage index to get more Messages
   * @returns Observable Of HTTPResponse
   */
  getMessagesNextPage(url) {
    return this.userInfoService.getCurrentUserInfo().pipe(
      concatMap((orgId) => {
        const response = this.authhttp.interceptHttpRequest(url, 'GET')
        return from(response);
      })
    );
  }

  /**
   * Save Message to Database.
   * @param message Message Object
   * @returns Observable
   */
   saveMessage(message) {
    this.updateData = this.userInfoService.getCurrentUserInfo().pipe(
      concatMap((orgId) => {
        const url = this.backendURL + orgId.orgId + '/' + orgId.userId + '/notification';
        const response = this.authhttp.interceptHttpRequest(url, 'POST', message)
        return from(response);
      })
    );
    return this.updateData;
  }

  /**
   * Delete Message From Database.
   * @param messageId Selected MessageId
   * @returns Observable of HTTPResponse
   */
  deleteMessage(messageId) {
    return this.userInfoService.getCurrentUserInfo().pipe(
      concatMap((orgId) => {
        const url = this.backendURL + orgId.orgId + '/' + orgId.userId + '/notification/' + messageId;
        const response = this.authhttp.interceptHttpRequest(url, 'DELETE')
        return from(response);
      })
    );
  }

  /**
   * Update Message Status
   * @param messageId Updated Message Object
   * @param readStatus Read Status
   * @returns Observable of HTTPResponse.
   */
  updateMessage(messageId, readStatus){
    return this.userInfoService.getCurrentUserInfo().pipe(
      concatMap((orgId) => {
        const url = this.backendURL + orgId.orgId + '/' + orgId.userId + '/notification/' + messageId;
        const response = this.authhttp.interceptHttpRequest(url, 'PATCH', {read: readStatus})
        return from(response);
      })
    );
  }

}

/**
 * Message Type Defined
 */
export type Message = {
  name: string,
  icon: MessageIcon,
  message: string,
  isRead: boolean,
  messageType: MessageType,
  messageCategory: MessageCategory
};

/**
 * Action Category Enum
 */
export enum MessageCategory{
  addInventory = 'addInventory',
  associateInventory = 'associateInventory',
  editInventory = 'editInventory',
  uploadInventory = 'uploadInventory',
  delete = 'delete',
  demand = 'demand',
  newVehicleAvailable = 'newVehicleAvailable'
}

/**
 * MessageIcon Enum
 */
export enum MessageIcon {
  addInventory = '/assets/icons/grey-blue/add_inventory.icon.svg',
  associateInventory = '/assets/icons/grey-blue/associate_inventory.icon.svg',
  editInventory = '/assets/icons/grey-blue/edit_inventory.icon.svg',
  uploadInventory = '/assets/icons/grey-blue/upload_inventory.icon.svg',
  delete = '/assets/icons/grey-blue/delete.icon.svg',
  demand = '/assets/icons/blue/demand.icon.svg'
}

/**
 * MessageType Enum
 */
export enum MessageType {
  success = 'S',
  error = 'E',
  Warning = 'W',
  info = 'I'
}
