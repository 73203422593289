import { Component, Input, OnInit } from '@angular/core';
import { DemandService } from 'src/app/feature-modules/feature-shared/service/demand.service';
import { PlatformService } from 'src/app/shared/services/platform.service';

/**
 * OpenDemandInput Component
 * Provides Input for adding Open demand Amount.
 */
@Component({
  selector: 'app-open-demand-input',
  templateUrl: './open-demand-input.component.html',
  styleUrls: ['./open-demand-input.component.scss'],
})
export class OpenDemandInputComponent implements OnInit {
@Input() element: any;
@Input() form;
calculate: boolean = false;
edit:boolean = true;
step:number;
focus:boolean = false;
isDesktop:boolean;

/**
 * Setup Dependancy Injection
 */
constructor(private demandService: DemandService, private platformService:PlatformService) {
  this.isDesktop =  this.platformService.isDesktop();
}

/**
 * Oninit Calls checkDemand()
 */
ngOnInit() {
 this.demandService.getCurrentStep().subscribe(step => this.step = step);
 this.checkDemand();
}

/**
 * It will be called onload and Checks if there is already Value in Form Controls Then sets icon Accordingly.
 */
checkDemand()
{
  if(this.form.controls[this.element.id].value > 0){
    this.calculate = true;
    this.edit = false;
   }
 //this.edit = !(this.form?.value['open_demand']?.value < 1);
}

/**
 * Icon Toggle Logic
 * @param event FocusOut event
 */
onFocusOutEvent(event: any){
 this.focus = false;

 if(event.target.value > 0){
   this.calculate = true;
   this.edit = false;
 }
 else{
   this.calculate = false;
   this.edit = true;
 }
}

/**
 * Icon Toggle Logic on Focus Event
 */
onFocusEvent(){
  this.focus = true;
  this.edit = true;
  this.calculate = false;
}
  

}
