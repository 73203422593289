import { Component, Input, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PlatformService } from 'src/app/shared/services/platform.service';

/**
 * Message Component.
 */
@Component({
  selector: 'app-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.scss'],
})
export class MessageComponent implements OnInit {

  /**
   * Input Decorators
   */
  @Input() name;
  @Input() message;
  @Input() iconUrl;
  @Input() messageType;
  @Input() isRead;
  isDesktop: boolean;

  /**
   * 
   * @param snackBar MaterialSnackBar Component Used in Mobile View.
   */
  constructor(private snackBar:MatSnackBar, private platformService:PlatformService) { 
    this.isDesktop = platformService.isDesktop();
  }

  /**
   * Opens Snackbar if Platform is Not Desktop.
   */
  ngOnInit() {
    if(!this.isDesktop){
      this.snackBar.open(this.message, 'dismiss', {duration: 2000})
    }
  }

/**
 * Set Text Color depending on MessageType ( Success or Error)
 */
  getTextColorCssKey(){
    if(this.messageType == 'S'){
      return "has-text-black"
      }else{
        return "has-text-danger"
      }
  }

}
