import { Component, Inject, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import { InventoryService } from '../../feature-shared/service/inventory.service';
import { MessageCategory, MessageIcon, MessageType } from '../../feature-shared/service/message.service';

@Component({
  selector: 'app-inventory-image-upload',
  templateUrl: './inventory-image-upload.component.html',
  styleUrls: ['./inventory-image-upload.component.scss'],
})
export class InventoryImageUploadComponent implements OnInit {
  imageURL;
  form:FormGroup;
  element;
  isChecked = false;
  fileName;

  constructor(
    private inventoryService: InventoryService,
    @Inject(MAT_DIALOG_DATA) data,
    private translate: TranslateService,
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<InventoryImageUploadComponent>
  ) {
    if(data){
      this.element = data?.element;
    }
  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      inventoryImage: ['']
    });

  }

  /**
   * dismissModal with Dialogref close Method
   */
  dismissModal() {
    this.dialogRef.close();
  }

  onChangeImage(event) {
    if (event.target.files.length > 0) {
      const image = event.target.files[0];
      this.fileName = image.name;
      this.form.get('inventoryImage').setValue(image);
    }
  }

  onInventoryImageUpload() {
    const formData = new FormData();
    formData.append('image', this.form.get('inventoryImage').value);
    this.inventoryService.uploadInventoryImage(formData, this.element).subscribe(
      (res) => {
        this.imageURL = `${environment.imagepath}${res.body.image}`;
        this.element.image_url = this.imageURL;
        this.inventoryService.updateInventory(this.element);
        this.dismissModal()
      },
      (err) => {
        console.log(err, 'Errror---');
      }
    );
  }


  /**
   * Reads File When DragandDrop Event.
   */
  onFiledropped(event){
    if (event.length > 1) {
      this.translate.get('myInventory.messages.uploadInventoryFailure.only1fileAllowed').subscribe((result)=>{
        this.inventoryService.errorMessage('Error', MessageIcon.uploadInventory, result , MessageType.error,MessageCategory.uploadInventory);
        })
      this.dialogRef.close();
    }
    else{
      if(event.item(0).type.includes('image')){
        const file: File = event.item(0);
        this.fileName = file.name;
        this.form.get('inventoryImage').setValue(file);
      }
    }
  }
}
