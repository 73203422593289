import { Router } from '@angular/router';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild, AfterViewChecked} from '@angular/core';

/**
 * NavigationMenuItem Component.
 */
@Component({
    selector: 'app-navigation-menu-item',
    templateUrl: './navigation-menu-item.component.html',
    styleUrls: ['./navigation-menu-item.component.scss'],
})
export class NavigationMenuItemComponent implements OnInit, AfterViewChecked {
    @Input() label;
    @Input() icon;
    @Input() isActive;
    @Input() color;
    @Input() url;
    @Input() position;
    @Input() countBadge;

    @Output() register = new EventEmitter<NavigationMenuItemData>();

    @ViewChild('content') content : ElementRef;

    /**
     * @param element DomElement Reference
     */
    constructor(private element: ElementRef, private router: Router) { }


    ngOnInit() {
     }
    
    /**
    *  Navigating By URL Onclick Event
    */
    click(ev) {
        this.router.navigateByUrl(this.url);
    }

    /**
     * Called after every check of the component's view. Applies to components only.
     * Setting Element Width on basis Of Position.
     */
    ngAfterViewChecked(): void {
        if(this.position>-1){
            this.register.emit({
                position: this.position,
                width: this.element.nativeElement.offsetWidth
            })
        }
    }

    /**
     * Add css class 'active' When Route is Active.
     */
    getHighlightClassIfRouteActive(){
        if(this.url==this.router.url){
            return 'active';
        }
        return '';

    }

}

/**
 * All Icons Use in Navigation Bar needs to assign Here.
 */
export enum Icons {
    whiteHome = "/assets/icons/white/home.icon.svg",
    whiteInventory = "/assets/icons/white/inventory.icon.svg",
    blueDemand = "/assets/icons/blue/demand.icon.svg",
    blueVehicle = "/assets/icons/blue/vehicle.icon.svg",
    greyVehicle = "/assets/icons/grey-blue/vehicle.icon.svg",
    notification ="/assets/icons/blue/message.icon.svg"
}

/**
 * Color Property Definations.
 */
export enum Colors {
    sortimoBlue = "sortimoBlue",
    lightBlue = "lightBlue",
    white = "whiteSchema",
    sortimoBlueLight ="sortimoBlueLight"
}

/**
 * Type Defination For NavigationItem
 */
export type NavigationMenuItemData = {
    position: number,
    width: number
}