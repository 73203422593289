import { Component, Input, OnInit } from '@angular/core';
import { PlatformService } from 'src/app/shared/services/platform.service';

/**
 * General Card Component.
 */
@Component({
  selector: 'app-general-card',
  templateUrl: './general-card.component.html',
  styleUrls: ['./general-card.component.scss'],
})
export class GeneralCardComponent implements OnInit {
  /**
  * GeneralCard Component Class Render Card with Given Input Values.
  */
  @Input() icon:string;
  @Input() label:string;
  @Input() notificationCount:string;
  isDesktop: boolean;

  constructor(private platformService:PlatformService) {
   }

  ngOnInit() {
    this.isDesktop = this.platformService.isDesktop();
  }

}
