import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { InventoryService } from '../../feature-shared/service/inventory.service';

/**
 * Delete Inventory Component.
 */
@Component({
  selector: 'app-delete-inventory',
  templateUrl: './delete-inventory.component.html',
  styleUrls: ['./delete-inventory.component.scss'],
})
export class DeleteInventoryComponent implements OnInit {
  inventories: any[];
  
  /**
   * setup Dependency Injections.
   */
  constructor(private inventoryService:InventoryService, private router: Router,
    @Inject(MAT_DIALOG_DATA) data, @Optional() public dialogRef: MatDialogRef<DeleteInventoryComponent>) {
        this.inventories = data?.inventories
  }

  ngOnInit() {}

  /**
   * Subscribes to the deleteInventories Observable in Inventory Service
   */
  deleteInventories(){
    this.inventoryService.deleteInventories(this.inventories).subscribe(resolve => {
      this.router.navigate(['/inventory']);
    });
  }

  closeDialog(){
    this.dialogRef.close();
  }

}
