import { NgZone } from '@angular/core';
import { Platform } from '@ionic/angular';
import { StorageBackend, Requestor } from '@openid/appauth';
import { AuthService, Browser } from 'ionic-appauth';
import { environment } from '../../../environments/environment';
import { App } from '@capacitor/app';
import { CustomAuthService } from '../customauth.service';

/**
 * Handles Authentication Entry Points For Application.
 * @param platform Array Of Platforms(android, web..)
 * @param requestor An class that abstracts away the ability to make an XMLHttpRequest.
 * @param browser Handles Inapp Browser Events.
 * @param storage Storage Api.
 * @returns 
 */
export const authFactory = (platform: Platform, ngZone: NgZone,
                            requestor: Requestor, browser: Browser, storage: StorageBackend) => {

  const authService = new CustomAuthService(browser, storage, requestor);
  authService.authConfig = environment.oidcConfig;

  /**
   * Sets RedirectUrl For Web Applicationa and Not Cordova.
   */
  if (!platform.is('cordova')) {
    authService.authConfig.redirect_url = window.location.origin + '/auth_callback';
    authService.authConfig.end_session_redirect_url = window.location.origin + '/auth_endsession';
  }

  /**
   * Sets RedirectUrl for Capacitor App and Satrts Authorization Callback Proces.
   */
  if (platform.is('capacitor')) {
    App.addListener('appUrlOpen', (data: any) => {
      if (data.url !== undefined) {
        ngZone.run(() => {
          if ((data.url).indexOf(authService.authConfig.redirect_url) === 0) {
            authService.authorizationCallback(data.url);
          } else {
            authService.endSessionCallback();
          }
        });
      }
    });
  }

  return authService;
};
