import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from './material/material.module';
import { MenuComponent } from './components/menu/menu.component';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { InventorySideMenuComponent } from './components/inventory-side-menu/inventory-side-menu.component';
import { FormsModule } from '@angular/forms';



@NgModule({
  declarations: [
    MenuComponent,
    InventorySideMenuComponent
  ],
  imports: [
    CommonModule,
    IonicModule, 
    TranslateModule,
    FormsModule,
    MaterialModule
  ], 
  exports:[
    MaterialModule,
    MenuComponent,
    InventorySideMenuComponent]
})
/**
* SharedModule class for All Component
*/
export class SharedModule { }
