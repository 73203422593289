import { HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'ionic-appauth';
import { from, Observable } from 'rxjs';
import { Http } from '@capacitor-community/http';
import { CustomAuthService } from './customauth.service';


@Injectable({
  providedIn: 'root'
})
export class AuthHttpService implements HttpInterceptor{

  constructor(private auth: CustomAuthService, private router: Router) { }

  /**
   * Identifies and handles a given HTTP request.
   * @param request The outgoing request object to handle.
   * @param next The next interceptor in the chain, or the backend.
   * @returns An observable of the event stream.
   */
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return from(this.handleAccess(request, next));
  }

  /**
   * Intercepts Http Request and check For Token Validation.
   * 
   * if Token is Valid add Bearer Token to Header.
   * @param request The outgoing request object to handle.
   * @param next The next interceptor in the chain, or the backend.
   * @returns Promise HttpEvent
   */
  private async handleAccess(request: HttpRequest<any>, next: HttpHandler): Promise<HttpEvent<any>> {
    try {
        let changedRequest = request;
        const token = await this.auth.getValidToken();
        if(token.isValid()){
          const headerSettings: { [name: string]: string | string[]; } = {};
          for (const key of request.headers.keys()) {
             headerSettings[key] = request.headers.getAll(key);
          }
          if (token) {
              headerSettings.Authorization = 'Bearer '+ token.accessToken;
              const newHeader = new HttpHeaders(headerSettings);
              changedRequest = request.clone({
              headers: newHeader,
          });
          }
         return next.handle(changedRequest).toPromise();
        }
        else {
          this.router.navigate(['/home']);
        }
    } catch (err) {
        return next.handle(request).toPromise();
    }
  }
  
  /**
   * Intercepts Http Request to Backend Server, Important to Work with @capacitor-community/http and Hybris.
   * 
   * @capacitor-community/http is Used as Normal HTTPClient is Not supported in Capacitor App.
   * @param url API URL
   * @param method 'GET, POST, PUT, DELETE'
   * @param data Optional Data
   * @returns Promise HttpResponse
   */
  async interceptHttpRequest(url, method, data?: any){
    const token = await this.auth.getValidToken();
    if(token.isValid()){
        const options = {url : url, headers: {'Authorization': 'Bearer ' + token.accessToken, 'Content-type': 'application/json'}, data: data}
        return  Http.request({...options, method: method})
    }
  }
}
