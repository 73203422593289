import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { VehicleService } from 'src/app/feature-modules/feature-shared/service/vehicle.service';

@Component({
  selector: 'app-inventory-side-menu',
  templateUrl: './inventory-side-menu.component.html',
  styleUrls: ['./inventory-side-menu.component.scss'],
})
export class InventorySideMenuComponent implements OnInit {
  @Input() url;
  vehicles: any[] = [];
  vehicles$: Observable<any>;
  query: any = '';
  tempVehicleData: any[] = [];

  constructor(private vehicleService: VehicleService, private router: Router) {
    this.vehicles$ = this.vehicleService.getVehicles();
   }

  ngOnInit() {
    this.vehicles$.subscribe(vehicles=> {
      this.vehicles = vehicles.data;
      this.tempVehicleData = this.vehicles.slice(0,5);
    });
  }
  click(url) {
    this.router.navigateByUrl(url);
}


onSearchTermChange() {
  if(this.query){
    return this.vehicles.filter(item => item.vehicle_name.toLowerCase().includes(this.query.toLowerCase()));
  }
  else{
    return this.tempVehicleData
  }
}

goToTotalInventory() {
  this.router.navigate(['/inventory']);
}

 /**
   * @ignore
   */
 onBlur(){
  this.query = '';
}

}
