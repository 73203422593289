import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { PlatformService } from 'src/app/shared/services/platform.service';
import { Message, MessageService, MessageIcon } from '../../../service/message.service';

/**
 * Messages Component.
 */
@Component({
  selector: 'app-messages',
  templateUrl: './messages.component.html',
  styleUrls: ['./messages.component.scss'],
})
export class MessagesComponent implements OnInit {
  opened: boolean = true;
  messages: any[] = [];
  visible: boolean= false;
  isDesktop: boolean;

  /**
  * subscribe to messagesService and get messages array.
  */
  constructor(private messagesService: MessageService, private platformService: PlatformService) {
    this.isDesktop = platformService.isDesktop();
    this.messagesService.messages.subscribe(messages => this.messages = [...messages])
  }

  ngOnInit() {
  }
}
