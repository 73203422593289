import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InventoryRoutingModule } from './inventory-routing.module';
import { MaterialModule } from 'src/app/shared/material/material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InventoryComponent } from './inventory.component';
import { TranslateModule } from "@ngx-translate/core";
import { InventoryDetailComponent } from './inventory-detail/inventory-detail.component';
import { FeatureSharedModule } from '../feature-shared/feature-shared.module';
import { IonicModule } from '@ionic/angular';
import { CreateInventoryComponent } from './create-inventory/create-inventory.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { UploadInventoryComponent } from './upload-inventory/upload-inventory.component';
import { DeleteInventoryComponent } from './delete-inventory/delete-inventory.component';
import { AssignInventoryComponent } from './assign-inventory/assign-inventory.component';
import { InventoryImageUploadComponent } from './inventory-image-upload/inventory-image-upload.component';

@NgModule({
  declarations: [
    InventoryComponent,
    InventoryDetailComponent,
    CreateInventoryComponent,
    UploadInventoryComponent,
    DeleteInventoryComponent,
    AssignInventoryComponent,
    InventoryImageUploadComponent
  ],
  imports: [
    CommonModule,
    InventoryRoutingModule,
    MaterialModule,
    IonicModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    FeatureSharedModule,
    SharedModule
  ]
})
export class InventoryModule { }
