import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, forkJoin, from, Observable, of } from 'rxjs';
import { concatMap, shareReplay, take, tap } from 'rxjs/operators';
import { AuthHttpService } from 'src/app/auth/auth-http.service';
import { UserinfoService } from 'src/app/core/services/userinfo.service';
import { InventoryInputElement } from 'src/app/shared/models/inventory';
import { environment } from 'src/environments/environment';
import { DropdownInput } from '../components/dynamic-forms/dynamic-inventory-input/input-dropdown';
import { NumberInput } from '../components/dynamic-forms/dynamic-inventory-input/input-number';
import { TextboxInput } from '../components/dynamic-forms/dynamic-inventory-input/input-text';
import { MessageCategory, MessageIcon, MessageService, MessageType } from './message.service';
import { VehicleService } from './vehicle.service';

/**
 * Inventory Service
 */
@Injectable({
  providedIn: 'root',
})
export class InventoryService {
  // TO DO: in Rxjs version 8 avoid `toPromise` and start using `lastValueFrom or firstValueFrom`.
  // Cache - shareReplay will save the latest value emitted and replay that value to any new subscriber
  backendURL = environment.backendUrlRoot + 'api/';
  inventories = { centralInventory: new BehaviorSubject<any[]>([]) };
  orgId: string;
  inventoryElement$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);

  constructor(
    private userInfoService: UserinfoService,
    private authhttp: AuthHttpService,
    private http: HttpClient,
    private messageService: MessageService,
    private vehicleService:VehicleService,
    private translate:TranslateService
  ) { 
    messageService.getAllMessages().subscribe();
  }

  /**
   * Creates Create Inventory Dialog Form With All Provided Fields
   * @param inventoryInputElements Inventory Input Fields
   */
  dynamicForm(inventoryInputElements) {
    // dynamic Form for create/Edit inventory
    return inventoryInputElements.map((inventory: InventoryInputElement) => {
      if (inventory.choices) {
        return new DropdownInput({
          key: inventory.id,
          label: inventory.id,
          type: 'text',
          required: inventory.required,
          maxLength: inventory.max_length,
          options: inventory.choices.map((choice) => {
            return {
              key: choice.value,
              value: choice.display_name,
            };
          }),
        });
      } else if (inventory.type == 'text' || inventory.type == 'textarea') {
        if (
          !(
            inventory.id.substring(inventory.id.length - 2) == 'id' ||
            inventory.id.includes('image') || inventory.id.includes('supplier_article_number')
          )
        ) {
          return new TextboxInput({
            key: inventory.id,
            label: inventory.id,
            type: 'text',
            required: inventory.required,
            maxLength: inventory.max_length,
          });
        }
      } else if (inventory.type == 'number') {
        if (!(inventory.id.includes('price_value')))
        {
          return new NumberInput({
            key: inventory.id,
            label: inventory.id,
            type: 'number',
            required: inventory.required,
            maxLength: inventory.max_length,
          });
  
        }
      }
    });
  }

  /**
   * Retrieves Inventory Name With ID.
   * @param id Inventory Id
   * @returns Promise with Inventory Name
   */
  getInventoryNameById(id){
    return this.getCentralInventory().toPromise().then((inventory)=>{
        return inventory.data.filter(inv => inv.uid == id).map((inv)=>inv.name)
      })
  }

  getInventoryFromId(inventory){
    return this.userInfoService.getCurrentUserInfo().pipe(
      concatMap((result) => {
        let url = ''
        this.orgId = result['orgId'];
        //Json.parse Json.stringify will ensure that request is a different object than inventory
         url = this.backendURL + result['orgId'] + '/inventory/' + inventory;
        const response = this.authhttp.interceptHttpRequest(url, 'GET')
        return from(response)
      }))
  }

  /**
   * Return Cached VehicleInventories.
   * @param vehicleId VehicleId
   * @returns Vehicle Inventory
   */
  getCachedInventoriesOfVehicle(vehicleId){
    // added this method for Vehicle Inventory to fix delete and edit from vehicle view
    if(!this.inventories[vehicleId]){
        this.inventories[vehicleId] = new BehaviorSubject<any[]>([])
    }
    this.getInventoriesVehicleInventory(vehicleId).subscribe((inventories)=>{
        this.inventories[vehicleId].next(inventories);
    });
    return this.inventories[vehicleId];
  }

  /**
   * Returns Cached Total Inventory.
   * @returns TotalInventory
   */
  getCachedInventoriesOfCentralInventory(){
    this.getCentralInventory().subscribe((inventories)=>{
        this.inventories['centralInventory'].next(inventories.data);
    });
    return this.inventories['centralInventory'];
  }

  /**
   * Fetch All Inventories From Database.
   * @returns Observable with Inventories
   */
  getCentralInventory() {
    // Get all-inventories from Server and cache it using shareReplay Subject.
    return this.userInfoService.getCurrentUserInfo().pipe(
      concatMap((result: any[]) => {
        const orgId = result['orgId'];
          const response = this.authhttp.interceptHttpRequest(this.backendURL + orgId + '/inventory', 'GET');
          return from(response)
      }),
      shareReplay(1));
  }

  /**
   * @param inventory Inventory
   * @param orgId Organization Id
   * @returns Returns Inventories Data with ORG_ID.
   */
  enrichInventory(inventory: any, orgId: string) {
    //add organizationId in Inventory Object
    inventory['organisation_id'] = orgId;
    return inventory;
  }

  /**
   * Post Inventory To Database.
   * @param inventory Inventory Data
   * @returns Promise with HTTPResponse
   */
  postInventory(inventory: any) {
    return this.userInfoService.getCurrentUserInfo().pipe(
      concatMap((result) => {
        const orgId = result['orgId'];
        return this.authhttp.interceptHttpRequest(this.backendURL + orgId + '/inventory', 'POST', this.enrichInventory(inventory, orgId));
      })).toPromise().then((resolved) => {
        let inventories = this.inventories['centralInventory'].value
        inventories = [...inventories, resolved];
        this.inventories['centralInventory'].next(inventories);
        this.translate.get('myInventory.messages.createInventorySuccess_Part1').subscribe((result)=>{
          this.translate.get('myInventory.messages.createInventorySuccess_Part2').subscribe((result1)=>{
          this.successMessage(inventory['name'], MessageIcon.addInventory, result + " " + inventory['name'] + " " + result1, MessageType.success ,MessageCategory.addInventory);});
        })
        return resolved;
    }, (reject) => {
        this.translate.get('myInventory.messages.createInventoryPostFailed').subscribe((result)=>{
        this.errorMessage(inventory['name'], MessageIcon.addInventory, result + " " + JSON.stringify(reject),MessageType.error, MessageCategory.addInventory);
        })
        throw (reject);
    })
  }

  /**
   * Retrives Inventories of Vehicle.
   * @param vehicleId VehicleId 
   * @returns Observable with Inventories of Given VehicleId
   */
  getInventoriesVehicleInventory(vehicleId: string) {
    // get inventory for selected Vehicle
    return this.userInfoService.getCurrentUserInfo().pipe(
      concatMap((result) => {
        const orgId = result['orgId'];
        const response = this.authhttp.interceptHttpRequest(this.backendURL + orgId + '/vehicle/' + vehicleId + '/inventory', 'GET');
        return from(response)
      }))
  }


  /**
   * Deletes Bulk Inventory.
   * @param inventories Array of Inventory Objects
   * @returns Observable with HTTPResponse
   */
  deleteInventories(inventories: any[]) {
    return forkJoin(inventories.map(
      (inventory) => {
        return this.deleteInventory(inventory);
      }
    ))
  }

  /**
   * Delete Single Inventory.
   * @param inventory Inventory Object
   * @returns Promise HttpResponse
   */
  deleteInventory(inventory) {
    return this.userInfoService.getCurrentUserInfo().pipe(
      concatMap((result) => {
        let url;
        if (inventory['vehicleId']) {
          url = this.backendURL + result['orgId'] + '/inventory-element/' + inventory['uid'];
        } else {
          url = this.backendURL + result['orgId'] + '/inventory/' + inventory['uid'];
        }
        const response = this.authhttp.interceptHttpRequest(url, 'DELETE')
        return from(response)
      })
    ).toPromise().then((resolved) => {
      if(resolved.status != 409){
        if (inventory['vehicleId']) {
          this.inventories[inventory['vehicleId']].next([...this.inventories[inventory['vehicleId']].value.filter((storedInventory) => { return storedInventory['uid'] != inventory['uid'] })]);
          this.translate.get('myInventory.messages.deleteInventorySuccess_Part1').subscribe((result)=>{
           this.translate.get('myInventory.messages.deleteInventorySuccess_Part2').subscribe((result1)=>{
           this.successMessage(inventory['name'], MessageIcon.delete, result +" "+ inventory['name'] + " "+ result1, MessageType.success,MessageCategory.delete);
          })
         })
       } else {
         this.inventories['centralInventory'].next([...this.inventories['centralInventory'].value.filter((storedInventory) => { return storedInventory['uid'] != inventory['uid'] })]);
         this.translate.get('myInventory.messages.deleteInventorySuccess_Part1') .subscribe((result1)=> {
           this.translate.get('myInventory.messages.deleteInventorySuccess_Part2').subscribe((result2)=> {
               this.successMessage(inventory['name'], MessageIcon.delete, result1 + " " + inventory['name'] + " " + result2,MessageType.success, MessageCategory.delete);
           })
         })
       }
       return (resolved);
      }
      else{
        this.translate.get('myInventory.messages.deletionFailed').subscribe((result)=>{
          this.translate.get('myInventory.messages.deletionFailedConnectionError').subscribe((result1)=>{
            this.errorMessage(inventory['name'], MessageIcon.delete, result + resolved.data.detail, MessageType.error,MessageCategory.delete);})        });
      }
    }).catch((reject) => {
      this.translate.get('myInventory.messages.deletionFailed').subscribe((result)=>{
        this.translate.get('myInventory.messages.deletionFailedConnectionError').subscribe((result1)=>{
        this.errorMessage(inventory['name'], MessageIcon.delete, result + (reject.error?.detail ?? result1), MessageType.error,MessageCategory.delete);})
      });
      throw (reject);
    })
  }

  /**
   * Update Inventory and Vehicle Inventory.
   * @param inventory Inventory Object With changes
   */
  updateInventory(inventory) {
    // update inventory element
    return this.userInfoService.getCurrentUserInfo().pipe(
      concatMap((result) => {
        let url = ''
        this.orgId = result['orgId'];
        //Json.parse Json.stringify will ensure that request is a different object than inventory
        const request = JSON.parse(JSON.stringify(inventory));
        if(inventory['vehicleId']){
            request['uid'] = inventory['uid'],
            request['inventory'] = inventory['parentInventoryId'],
            request['vehicle'] = inventory['vehicleId'],
            request['target_amount'] = inventory['target_amount']
        url = this.backendURL + this.orgId + '/inventory-element/'+ inventory['uid'];
/*         request['uid'] = inventory['parentInventoryId'];
        request['target_amount'] = inventory['original_target_amount'];
       url = this.backendURL + result['orgId'] + '/inventory/' + request['uid']; */
        } else {
        url = this.backendURL + result['orgId'] + '/inventory/' + request['uid'];
        }
        const response = this.authhttp.interceptHttpRequest(url, 'PUT', request)
        return from(response)
      })).toPromise().then(resolved => {
        //if(inventory['vehicleId']){
         /*  console.log(inventory, resolved.data, 'inventoriess---', inventory['vehicleId'])
          let inventories = this.inventories[inventory['vehicleId']].value
          let index = inventories.map(inventory => inventory.parentInventoryId).indexOf(resolved['uid']);
          //create call that changes inventory element
          const inventoryElementUrl = this.backendURL + this.orgId + '/inventory-element/'+ inventory['uid'];
          const request2 = {
              uid: inventory['uid'],
              inventory: inventory['parentInventoryId'],
              vehicle: inventory['vehicleId'],
              target_amount: inventory['target_amount']
          }
          const response = this.authhttp.interceptHttpRequest(inventoryElementUrl, 'PUT', request2)
          return from(response).subscribe((inventoryElement)=> {
              resolved['parentInventoryId'] = resolved['uid'];
              resolved['uid'] = inventoryElement['uid'];
              resolved['target_amount'] = inventoryElement['target_amount'];
              resolved['vehicleId'] = inventoryElement['vehicle']
              inventories[index] = resolved;
              this.inventories[inventory['vehicleId']].next(inventories);
          })
        } else { */
          let inventories = this.inventories['centralInventory'].value;
          let index = inventories.map(inv => inv.uid).indexOf(resolved.data['uid']);
          inventories[index] = resolved.data;
          this.inventories['centralInventory'].next(inventories);
        //}
          forkJoin([this.translate.get('myInventory.messages.editInventoryPutSuccess_Part1'), this.translate.get('myInventory.messages.editInventoryPutSuccess_Part2')])
          .subscribe((result)=>{
            this.successMessage(inventory['name'], MessageIcon.editInventory, result[0] + "" + result[1],MessageType.success,MessageCategory.editInventory);
          })        
        return (resolved.data);
      }, (reject) => {
        this.translate.get('myInventory.messages.editInventoryPutFailed').subscribe((result)=>{
          this.errorMessage(inventory['name'], MessageIcon.delete, result + " " + JSON.stringify(reject),MessageType.error, MessageCategory.delete);
        })
        throw(reject);
    })
  }


  // inventory-Element

  /**
   * Mapping InventoryElement into Inventories.
   */
  public mapInventoryElementsIntoInventories(vehicleInventories: any[], inventoryElements: any[], vehicleId: string) {
    // map vehicle_Id and InventoryElements into Vehicle inventory for Associate inventory Purpose.
    return vehicleInventories.map((vehicleInventory) => {
      const filteredInventoryElements = inventoryElements.filter(inventoryElement => {
        return inventoryElement.inventory == vehicleInventory.uid && inventoryElement.vehicle == vehicleId;
      });
      vehicleInventory['parentInventoryId'] = vehicleInventory['uid'];
      vehicleInventory['original_target_amount'] = vehicleInventory['target_amount'];
      vehicleInventory['uid'] = filteredInventoryElements[0]['uid'];
      vehicleInventory['target_amount'] = filteredInventoryElements[0]['target_amount'];
      vehicleInventory['vehicleId'] = vehicleId;
      return vehicleInventory;
    });
  }

  /**
   * @param inventoryid InventoryId
   * @param vehicleId VehicleID
   * @param target_amount Target Amount to order for Vehicle
   * @returns 
   */
  createInventoryElement(inventoryid: string, vehicleId: string, target_amount?: any) {
    return this.userInfoService.getCurrentUserInfo().pipe(
      concatMap((result: any[]) => {
        const url = this.backendURL + result['orgId'] + '/inventory-element';
        const request = {
          inventory: inventoryid,
          vehicle: vehicleId,
          target_amount: target_amount
        }
        const response =  this.authhttp.interceptHttpRequest(url, 'POST', request)
        return from(response)
      })).toPromise().then((inventoryElement: any) => {
        let inventoriesWithoutVehicleInformation = this.inventories['centralInventory'].value.filter((inventory) => {
          return inventoryElement.data.inventory == inventory.uid && inventoryElement.data.vehicle == vehicleId
        });
        const enrichedVehicleInventories = this.mapInventoryElementsIntoInventories(inventoriesWithoutVehicleInformation, [inventoryElement.data], vehicleId);
        if (this.inventories[vehicleId]) {
          this.inventories[vehicleId].next([...this.inventories[vehicleId], enrichedVehicleInventories]);
        } else {
          this.inventories[vehicleId] = new BehaviorSubject<any[]>(enrichedVehicleInventories);
        }
        this.translate.get('myInventory.messages.createVehicleInventorySuccess_Part1').subscribe((result)=>{
          this.translate.get('myInventory.messages.createVehicleInventorySuccess_Part2').subscribe((result1)=>{
            this.getInventoryNameById(inventoryid).then(inv => {
            this.vehicleService.getVehicleNameById(vehicleId).then((id) => { 
              this.successMessage(inv.toString(),MessageIcon.associateInventory, result + " " + id.toString() + " " + result1, MessageType.success,MessageCategory.associateInventory)
            })
            });
          });
      });
      })
  }

  /**
   * Retrieves InventoryElemets (Assigned Inventories)
   */
  getInventoryElement() {
    return this.userInfoService.getCurrentUserInfo().pipe(
      concatMap((result: any[]) => {
        let url = this.backendURL + result['orgId'] + '/inventory-element';
        const response = this.authhttp.interceptHttpRequest(url, 'GET')
        return from(response).pipe();
      }))
  }

  /**
   * Delete InventoryElement.
   * @param inventoryElement InventoryElement Object
   * @returns Promise HttpResponse
   */
  deleteInventoryElement(inventoryElement:any){
    return this.userInfoService.getCurrentUserInfo().pipe(
        concatMap((result: any[]) => {
            this.orgId = result['orgId'];
            let url = this.backendURL + this.orgId + '/inventory-element/' + inventoryElement['uid'];
            const response = this.authhttp.interceptHttpRequest(url, 'DELETE')
            return from(response).pipe(shareReplay(1));        
        })).toPromise().then((resolved) => {
          this.translate.get('myInventory.messages.deleteInventorySuccess_Part1').subscribe((result)=>{
            this.translate.get('myInventory.messages.deleteInventorySuccess_Part2').subscribe((result1)=>{
              this.getInventoryNameById(inventoryElement['inventory']).then(inv => {
                this.successMessage(inv.toString(), MessageIcon.delete, result + result1,MessageType.success, MessageCategory.delete);
              })
          })})
          return (resolved);
      }, (reject) => {
        this.translate.get('myInventory.messages.deletionFailed').subscribe((result)=>{
          this.translate.get('myInventory.messages.deletionFailedConnectionError').subscribe((result1)=>{  
            this.getInventoryNameById(inventoryElement['inventory']).then(inv => {
              this.errorMessage(inv.toString(), MessageIcon.delete, result + (reject.error?.detail ?? result1), MessageType.error,MessageCategory.delete);
            })
            })})
        throw (reject);
      })
    }
  
    /**
     * Update InventoryElement 
     * @param inventoryElement InventoryElement Objects
     * @param inventoryid InventoryId
     * @param vehicleId VehicleId
     * @param target_amount Updated Target Amount
     * @returns 
     */
    updateInventoryElement(inventoryElement, inventoryid: string, vehicleId: string, target_amount?: any){
        return this.userInfoService.getCurrentUserInfo().pipe(
            concatMap((result) => {
                this.orgId = result['orgId'];
                const request = {
                    uid: inventoryElement['uid'],
                    inventory: inventoryid,
                    vehicle: vehicleId,
                    target_amount: target_amount
                }
                const url = this.backendURL + this.orgId + '/inventory-element/'+ inventoryElement['uid'];
                const response = this.authhttp.interceptHttpRequest(url, 'PUT', request)
                return from(response).pipe(shareReplay(1));        
            })).toPromise().then((resolved) =>{
              this.translate.get('myInventory.messages.editInventoryPutSuccess_Part1').subscribe((result)=>{
                this.translate.get('myInventory.messages.editInventoryPutSuccess_Part2').subscribe((result1)=>{  
                  this.getInventoryNameById(inventoryElement['inventory']).then(inv => {
                  this.vehicleService.getVehicleNameById(resolved.data.vehicle).then((id) => { 
                    this.successMessage(inv.toString(), MessageIcon.editInventory, result + id.toString() + result1, MessageType.success, MessageCategory.editInventory);
                  })
                })
                  })
                })
              return (resolved.data);
          }, (reject) => {
            this.getInventoryNameById(inventoryElement['inventory']).then(inv => {
            this.translate.get('myInventory.messages.editInventoryPutFailed').subscribe((result)=>{
              this.errorMessage(inv.toString(), MessageIcon.delete, result + " " + JSON.stringify(reject),MessageType.error, MessageCategory.delete);
            })
            })
              throw (reject);
          })
    } 

  public uploadInventoryImage(formData, element): Observable<any> {
    // change HttpClient with @capacitor-community/http when support is ready
      return this.userInfoService.getCurrentUserInfo().pipe(
        concatMap((result) => {
          const url = this.backendURL + result["orgId"] + '/upload/inventory/image/' + element.uid;
          return this.http.post(url, formData, {
            reportProgress: true,
            observe: "events",
          });
        }),
      )
  }

  /**
   * Creates New Success Message Format
   */
  successMessage(inventoryName: string, type: MessageIcon, message: string,message_type: MessageType, category: MessageCategory) {
    this.messageService.newMessage(inventoryName, type, message, message_type, category);
  }

  /**
   * Creates New Error Message Format
   */
  errorMessage(inventoryName: string, type: MessageIcon, message: string,message_type: MessageType, category: MessageCategory) {
    this.messageService.newMessage(inventoryName, type, message, message_type, category);
  }

}
