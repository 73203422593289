import { Pipe, PipeTransform } from '@angular/core';

/**
 * Pipe is Used to search VehicleName using Id or name.
 */
@Pipe({
  name: 'extractVehiclenameOfModel'
})
export class ExtractVehiclenameOfModelPipe implements PipeTransform {
  transform(value: any[], filterText: string, fieldName?:string): any {
  if(!value){return [];}
  if (!filterText) {return value;}
  
  filterText = filterText.toLowerCase();
  return value.filter(i => {
    return JSON.stringify(i).toLowerCase().includes(filterText);
  });
  }

}
