import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy, RouterModule } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CommonModule } from '@angular/common';
import { HomeModule } from './feature-modules/home/home.module';
import { SharedModule } from './shared/shared.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthModule } from './auth/auth.module';
import { CoreModule } from './core/core.module';
import { AuthHttpService } from './auth/auth-http.service';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { SortimoTranslationLoader } from './core/services/translation/sortimo-translation-loader';
import { InventoryModule } from './feature-modules/inventory/inventory.module';
import { DemandModule } from './feature-modules/demand/demand.module';

@NgModule({
    declarations: [AppComponent],
    imports: [
        CommonModule,
        BrowserModule,
        IonicModule.forRoot({
            swipeBackEnabled: false,
            platform: {
                /** The default `desktop` function returns false for devices with a touchscreen.
                * This is not always wanted, so this function tests the User Agent instead.
                **/
                'desktop': (win) => {
                    const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(win.navigator.userAgent);
                    return !isMobile;
                }
            },
        }),
        AppRoutingModule,
        RouterModule,
        HomeModule,
        InventoryModule,
        DemandModule,
        SharedModule,
        CoreModule,
        BrowserAnimationsModule,
        HttpClientModule,
        AuthModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useClass: SortimoTranslationLoader,
                //useFactory: createTranslateLoader,
                deps: [HttpClient]
            }
        })
    ],
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: AuthHttpService, multi: true },
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }
    ],
    bootstrap: [AppComponent]
})
export class AppModule {}

/**
 * Use createTranslateLoader() To implement Transltion inside Project.
 * currently Translation is managed Using sortimo-translation-loader.
 */
export function createTranslateLoader(http: HttpClient) {
  const path = window.location.origin + '/assets/i18n/';
    return new TranslateHttpLoader(http, path);
  }