import { Component, Input, OnInit } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { InventoryService } from "../../service/inventory.service";
import { StateService } from "../../service/state.service";
import { VehicleService } from "../../service/vehicle.service";

/**
 * Associate Vehicle Component.
 */
@Component({
  selector: "associate-vehicle",
  templateUrl: "./associate-vehicle.component.html",
  styleUrls: ["./associate-vehicle.component.scss"],
})
export class AssociateVehicleComponent implements OnInit {
  vehicleQuery;
  vehicles: any[];
  form: UntypedFormGroup;
  controlGroup: any = {};
  @Input() inventory;
  inventoryElement: any[] = [];
  existingElement: any[] = [];

  /**
   * Setup Dependency Injections.
   * Subscribing to vehicle Service getVehicles() method and create FormControl With Value.
   */
  constructor(
    private vehicleService: VehicleService,
    private inventoryService: InventoryService,
    private stateService: StateService
  ) {
    this.vehicleService.getVehicles().subscribe((result) => {
      this.vehicles = result.data
      this.vehicles?.forEach((vehicle) => {
        this.controlGroup[vehicle.vehicle_id + "_checked"] = new UntypedFormControl(
          false
        );
        this.controlGroup[vehicle.vehicle_id + "_amount"] = new UntypedFormControl({
          value: 0,
          disabled: true,
        });
      });
      this.form = new UntypedFormGroup(this.controlGroup);
    });

    this.inventoryService.getInventoryElement().subscribe((result) => {
      this.inventoryElement = result.data;
    });
  }

  ngOnInit() {}

  /**
  * Check For Already Existing inventoryElement.
  * Element Already Exists then It will be update unless It creates New one.
  */
  output(inventoryId) {
    Object.keys(this.form.value).forEach((formkey) => {
      const vehicleId = formkey.replace("_checked", "");
      this.existingElement = this.inventoryElement?.filter((e) => {
        return e.inventory == inventoryId && e.vehicle == vehicleId;
      });
      if (this.form.value[formkey]) {
        const amount = this.form.value[formkey.replace("_checked", "_amount")];
        if(this.existingElement.length > 0) {
          this.existingElement.forEach((invElement) => {
            this.inventoryService.updateInventoryElement(invElement, invElement['inventory'], invElement['vehicle'], amount);
          });
        } else {
          this.inventoryService.createInventoryElement(inventoryId, vehicleId, amount);
        }
      } else if (!this.form.value[formkey]) {
        /**
         * Above Condition Fulfils then inventoryElement will be Removed.
         */
        this.existingElement.forEach((inventoryElement) => {
          this.inventoryService.deleteInventoryElement(inventoryElement).then();
        });
      }
    });
  }
}
