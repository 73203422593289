
import { Component, Injectable, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatMenu } from '@angular/material/menu';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { CreateInventoryComponent } from 'src/app/feature-modules/inventory/create-inventory/create-inventory.component';
import { StateService } from 'src/app/feature-modules/feature-shared/service/state.service';
import { PlatformService } from '../../services/platform.service';
import { UploadInventoryComponent } from 'src/app/feature-modules/inventory/upload-inventory/upload-inventory.component';
import { DeleteInventoryComponent } from 'src/app/feature-modules/inventory/delete-inventory/delete-inventory.component';
import { AssignInventoryComponent } from 'src/app/feature-modules/inventory/assign-inventory/assign-inventory.component';

@Injectable()
@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
  exportAs: 'menuInOtherComponent'
})
export class MenuComponent implements OnInit {
  @ViewChild(MatMenu, {static: true}) menu: MatMenu;
  @Input() isHome:boolean;
  @Input() vehicle: string;
  @Input() demand: string;

  inventories:any[];
  isDesktop:boolean;
  vehicleInventoryMenu: boolean = true;

   /**
   * setup Dependency Injections.
   */
  constructor(public dialog: MatDialog, private stateService: StateService, private router: Router, private platformService:PlatformService, private modalController:ModalController) {
      this.isHome = false;
      this.stateService.selectedInventories.subscribe((inventories)=>{
          this.inventories = inventories;
      });
      this.isDesktop =  this.platformService.isDesktop();
  }

  ngOnInit() {
    this.router.url.includes(this.vehicle) ? this.vehicleInventoryMenu = false : this.vehicleInventoryMenu = true
  }

  /**
  * Redirects To Vehicleselection Page
  */
  addVehicle(){
    window.location.href = "https://www.sortimo-test.de/de/vehicleselection";
  }

  /**
  * Open Create inventory Dialog
  */
  openCreateInventoryDlg(){
      const dialogRef = this.dialog.open(CreateInventoryComponent,{restoreFocus: false});
      dialogRef.disableClose = true;  
  }

  /**
  * Open Create inventory Modal in Mobile view
  */
  async openCreateInventoryModal(){
    const modal = await this.modalController.create({
      component: CreateInventoryComponent
    });

    await modal.present();
  }

  /**
  * Open Upload inventory Dialog
  */
  openInventoryUploadDialog(){
    const dialogRef = this.dialog.open(UploadInventoryComponent,{restoreFocus: false});
    dialogRef.disableClose = true;
  }
  
  /**
  * Open Edit inventory Dialog
  */
  openEditInventoryDlg(){
    const dialogRef = this.dialog.open(CreateInventoryComponent,{restoreFocus: false,
                                                                 data:{inventory: this.inventories[0]}});
    dialogRef.disableClose = true;
  }

  /**
  * Open Delete inventory Dialog
  */
  openDeleteInventoryDlg(){
    const dialogRef = this.dialog.open(DeleteInventoryComponent,{restoreFocus: false,
                                                                 data:{inventories: this.inventories}});
    dialogRef.disableClose = true;
  }

  /**
  * Open Assign inventory Dialog
  */
  openAssignInventoryDlg(){
    const dialogRef = this.dialog.open(AssignInventoryComponent,{restoreFocus: false,
                                                                 data:{inventories: this.inventories}});
    dialogRef.disableClose = true;
  }

  /**
  * Navigate to demand creation Page
  */
  openEditDemand(){
    this.router.navigate(['/demands'], {
      state: {origin:this.router.url, vehicle: this.vehicle, demand: this.demand}
    });
  }

}
