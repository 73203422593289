import { concatMap, shareReplay } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { from, Observable } from 'rxjs';
import { UserinfoService } from 'src/app/core/services/userinfo.service';
import { AuthHttpService } from 'src/app/auth/auth-http.service';
import { environment } from 'src/environments/environment';

/**
 * Vehicle Service
 */
@Injectable({
    providedIn: 'root'
})
export class VehicleService {

    data: Observable<any>;
    vehicles: Observable<any>;
    backendURL = environment.backendUrlRoot + 'api/';
    constructor(private http: HttpClient, private userInfoService: UserinfoService, private authhttp: AuthHttpService) {
    }

    /**
     * Returns VehicleName With ID
     * @param id Vehicle Id
     * @returns Promise
     */
    getVehicleNameById(id){
       return this.getVehicles().toPromise().then((vehicles)=>{
             return vehicles.data.filter(vehicle => vehicle.vehicle_id == id).map((vehicle)=>vehicle.vehicle_name)
         })
    }
    
    /**
     * Fetch All Vehicle Data from Database.
     * @returns Observable with VehicleData
     */
     getVehicles() {
        if(!this.data){
            this.data = this.userInfoService.getCurrentUserInfo().pipe(
                concatMap((orgId)=>{
                if(!this.vehicles){
                   const response = this.authhttp.interceptHttpRequest(this.backendURL + orgId['orgId'] + '/vehicle', 'GET');
                   this.vehicles = from(response).pipe(shareReplay(1));
                  }
                  return this.vehicles
                }))}
            return this.data;
    }

}
