import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomeComponent } from './home.component';
import { HomePageRoutingModule } from './home-routing.module';
import { FeatureSharedModule } from '../feature-shared/feature-shared.module';
import { MaterialModule } from 'src/app/shared/material/material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from "@ngx-translate/core";
import { SharedModule } from 'src/app/shared/shared.module';


@NgModule({
  declarations: [HomeComponent],
  imports: [
    CommonModule,
    HomePageRoutingModule,
    FeatureSharedModule,
    MaterialModule,
    FormsModule,
    IonicModule,
    ReactiveFormsModule,
    TranslateModule,
    SharedModule
  ],
  exports:[HomeComponent]
})
export class HomeModule { }
