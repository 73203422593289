import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuardService } from './auth/auth-guard.service';
import { AllDemandViewComponent } from './feature-modules/demand/all-demand-view/all-demand-view.component';
import { DemandDetailViewComponent } from './feature-modules/demand/all-demand-view/demand-detail-view/demand-detail-view.component';
import { DemandComponent } from './feature-modules/demand/demand.component';
import { AllMessagesComponent } from './feature-modules/feature-shared/components/notifications/all-messages/all-messages.component';
import { InventoryDetailComponent } from './feature-modules/inventory/inventory-detail/inventory-detail.component';

const routes: Routes = [
  
  { path: 'auth_callback', loadChildren: () => import('./auth/auth-callback/auth-callback.module').then(m => m.AuthCallbackPageModule) },
  { path: 'auth_endsession', loadChildren: () => import('./auth/end-session/end-session.module').then(m => m.EndSessionPageModule) },
  { path: 'login', loadChildren: () => import('./login/login.module').then(m => m.LoginPageModule) },
  {
    path: 'home',
    canActivate:[AuthGuardService],
    loadChildren: () => import('./feature-modules/home/home.module').then(m => m.HomeModule)
  },
  {
    path: 'inventory',
    canActivate: [AuthGuardService],
    loadChildren: () => import('./feature-modules/inventory/inventory.module').then(m => m.InventoryModule)
  },
  {
    path: 'inventory/:id',
    canActivate: [AuthGuardService],
    loadChildren: () => import('./feature-modules/inventory/inventory.module').then(m => m.InventoryModule)
  },
  {
    path: 'inventoryDetails/:id',
    canActivate: [AuthGuardService],
    component: InventoryDetailComponent
  },
  {
    path: 'demands',
    canActivate: [AuthGuardService],
    component: DemandComponent
  },
  {
    path: 'alldemands',
    canActivate: [AuthGuardService],
    component: AllDemandViewComponent
  },
  {
    path: 'demandDetails/:id',
    canActivate: [AuthGuardService],
    component: DemandDetailViewComponent
  },
  {
    path: 'messages',
    canActivate: [AuthGuardService],
    component:AllMessagesComponent
  },
  {
    path: '**',
    redirectTo: 'home',
    pathMatch: 'full'
  },
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules , relativeLinkResolution: 'legacy'})
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
