import { AuthActionBuilder, AuthService, Browser, IAuthService } from "ionic-appauth";
import { take } from "rxjs/operators";
import { TokenResponse, TokenRequest, TokenRequestJson, GRANT_TYPE_REFRESH_TOKEN , Requestor, StorageBackend} from '@openid/appauth';
import { Injectable } from "@angular/core";


/**
 * Constant variable for TokenResponse Key
 */
const TOKEN_RESPONSE_KEY = "token_response";

@Injectable()
export class CustomAuthService extends AuthService implements IAuthService {
    constructor(browser?: Browser, storage?: StorageBackend, requestor?: Requestor) {
        super(browser, storage, requestor);
    }
    /**
     * Overridding Appauth Service Method in Oder to Refresh Token with Client_secret.
     */
    protected async requestTokenRefresh() {
        const _token = await this.token$
            .pipe(take(1))
            .toPromise();
  
        if (!_token) {
            throw new Error("No Token Defined!");
        }
  
        let requestJSON: TokenRequestJson = {
            grant_type: GRANT_TYPE_REFRESH_TOKEN,
            refresh_token: _token.refreshToken,
            redirect_uri: this.authConfig.redirect_url,
            client_id: this.authConfig.client_id,
            extras: { "client_secret": this.authConfig.client_secret }
        };
  
        let token: TokenResponse = await this.tokenHandler.performTokenRequest(await this.configuration, new TokenRequest(requestJSON));
        await this.storage.setItem(TOKEN_RESPONSE_KEY, JSON.stringify(token.toJson())).catch();
        this.notifyActionListers(AuthActionBuilder.RefreshSuccess(token));
    }
  
    /**
     * Handle Request for Token Refresh.
     */
    public async refreshToken() {
        await this.requestTokenRefresh().catch((response) => {
            this.storage.removeItem(TOKEN_RESPONSE_KEY);
            this.notifyActionListers(AuthActionBuilder.RefreshFailed(response));
        });
    }
}