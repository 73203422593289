import { Component, Inject, OnInit, Optional, ViewEncapsulation } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ModalController, NavParams } from '@ionic/angular';
import { PlatformService } from 'src/app/shared/services/platform.service';
import { InventoryService } from '../../feature-shared/service/inventory.service';
import { VehicleService } from '../../feature-shared/service/vehicle.service';
import { Observable } from 'rxjs';

/**
 * Assign Inventory Component.
 */
@Component({
  selector: 'app-assign-inventory',
  templateUrl: './assign-inventory.component.html',
  styleUrls: ['./assign-inventory.component.scss'],
})
export class AssignInventoryComponent implements OnInit{
  affectedInventories: any[] = [];
  vehicles:any[] = [];
  vehicleQuery: any = '';
  filteredInventoryElements: any[] = [];
  form: UntypedFormGroup;
  controlGroup: any = {};
  inventoryElement: any[] = [];
  existingElement: any[] = [];
  isDesktop:boolean;
  vehicles$: Observable<any>;
  hideAmount: boolean;

  /**
   * setup Dependency Injections
   * 
   * Optional decorator marks the parameter as being an optional dependency.
   * 
   * Navparams and DialogData are Optional here as you can have only one at a time.
   * @param navParams similar to MatDialogData but for Ionic(Mobile) App.
   * @param data  MatDialogData ref to use data that was passed in to a dialog.
   * @param dialogRef MatDialog Reference for opening and Performing Actions on Assigninventory dialog.
   * @param modalController similar to Matdialog for Ionic(Mobile) App. 
   */
  constructor(@Optional() @Inject(MAT_DIALOG_DATA) data,@Optional() public dialogRef: MatDialogRef<AssignInventoryComponent>,private modalController:ModalController,private vehicleService: VehicleService,
  private inventoryService: InventoryService, @Optional() private navParams: NavParams, private platformService: PlatformService) {
    this.vehicles$ = this.vehicleService.getVehicles();
    if(data){
      this.affectedInventories = data?.inventories;
    }
    else if(navParams.data){
      const inventory = navParams.data.inventory;
      inventory? this.affectedInventories.push(inventory) : this.affectedInventories = this.navParams.data?.inventories;
    }
    this.associatedVehicle();
  }
  
  /**
  * dismissModal with Dialogref close Method if Desktop and For mobile with ModalController dismiss Method
  */
  dismissModal() {
    if(this.isDesktop){
        this.dialogRef.close()
    }
    else{
      this.modalController.dismiss({
        'dismissed': true,
      });  
    }
  }

  /**
   * Lifecycle Method Oninit:
   * 
   * Subscribes to Observable vehicle$ Creating FormGroup.
   */
  ngOnInit() {
    this.vehicles$.subscribe((result)=>{
      this.vehicles = result.data;
      this.vehicles?.forEach((vehicle) => {
        this.controlGroup[vehicle.vehicle_id + "_checked"] = new UntypedFormControl(
          false
        );
        this.controlGroup[vehicle.vehicle_id + "_amount"] = new UntypedFormControl({
          value: 0,
          disabled: true,
        });
            });
      this.form = new UntypedFormGroup(this.controlGroup);
    });
    this.isDesktop = this.platformService.isDesktop();
  }

  /**
   * Subscribe to getInventoryElement Method From Inventory Service.
   * 
   * Performing Filter Operation on Return Value From subscription.
   * 
   * Passed Filtered Value to associatedVehicleForSelectedInventory() Method.
   */
  associatedVehicle(){
    this.inventoryService.getInventoryElement().subscribe((result)=> {
      this.inventoryElement = result.data;
        this.affectedInventories?.forEach((selectedInventory) => {
          const inventoryElement = result.data.filter(invElement => {
            return invElement.inventory == selectedInventory.uid;
        })
          this.filteredInventoryElements.push(...inventoryElement)
      })
    this.associatedVehicleForSelectedInventory(this.filteredInventoryElements);
    })
  }

  /**
   * Subscribes to Observable vehicle$.
   * 
   * Sets a form controls value For Selected Inventory.
   * 
   * Disabled already Assigned Inventory to Selected Vehicle.
   */
  associatedVehicleForSelectedInventory(inventoryElement: any[]){
    this.vehicles$.subscribe((result)=>{
      this.vehicles = result.data;
      inventoryElement?.map(invElement=> {
      this.vehicles.forEach(vehicle=>{
          var vehicleInv = inventoryElement.filter(el=> el.vehicle == vehicle.vehicle_id)
          if(vehicleInv.length == this.affectedInventories.length){
            this.form.controls[vehicle.vehicle_id+"_checked"].setValue(true);
            this.form.controls[vehicle.vehicle_id+"_amount"].setValue(invElement.target_amount)      
            this.form.controls[vehicle.vehicle_id+"_amount"].enable()
            this.hideAmount = false
            if(this.affectedInventories.length > 1){
              this.hideAmount = true;
              this.form.controls[vehicle.vehicle_id+"_checked"].disable();
            }
          }
      });
    })
    });
  }


  /**
   * Handles Value of target amount on Checkbox Change Event.
   */
  checkBoxChange($event){
    this.vehicles.forEach(vehicle=>{

    if(this.form.controls[vehicle.vehicle_id+"_checked"].value){
      this.form.controls[vehicle.vehicle_id+"_amount"].enable()
    }
    else {
      this.form.controls[vehicle.vehicle_id+"_amount"].disable()
      this.form.controls[vehicle.vehicle_id+"_amount"].setValue(0);
    }
    })
  }

  private extractIdsFromInventory(inventories:any[]){
    return inventories?.map((inventory) => inventory.uid);
  }

  /**
  * Subscribes to create and delete InventoryElement from Inventory Service.
  */
  appendVehicles(){
    const affectedInventoryIds = this.extractIdsFromInventory(this.affectedInventories);
    affectedInventoryIds?.map(inventoryId => {
      Object.keys(this.form?.value).forEach((formkey) => {
        const vehicleId = formkey.replace("_checked", "");
        this.existingElement = this.inventoryElement?.filter((e) => {
          return e.inventory == inventoryId && e.vehicle == vehicleId;
        });
        if (this.form.value[formkey]) {
          const amount = this.form.value[formkey.replace("_checked", "_amount")];
          if(this.existingElement.length > 0) {
            this.existingElement.forEach((invElement) => {
              this.inventoryService.updateInventoryElement(invElement, invElement['inventory'], invElement['vehicle'], amount);
              this.dismissModal();
            });
          } else if(this.existingElement.length <= 0) {
            this.inventoryService.createInventoryElement(inventoryId, vehicleId, amount);
            this.dismissModal();
          }
        }
        else if (this.form.value[formkey] == false) {
          this.existingElement.forEach((inventoryElement) => {
            this.inventoryService.deleteInventoryElement(inventoryElement).then((resolve)=>{
              this.dismissModal();
            });
          })
        }
      });
    })
  }
}
