import { Component, Input, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { UserinfoService } from 'src/app/core/services/userinfo.service';
import { DemandService } from 'src/app/feature-modules/feature-shared/service/demand.service';
import { PlatformService } from 'src/app/shared/services/platform.service';
/**
 * Vehicle demand list component
 */
@Component({
  selector: 'app-vehicle-demand-list',
  templateUrl: './vehicle-demand-list.component.html',
  styleUrls: ['./vehicle-demand-list.component.scss'],
})
export class VehicleDemandListComponent implements OnInit {
  displayedColumns: string[] = ['created_at', 'assignee', 'status', 'finished_at', 'moreinfo'];
  dataSource: MatTableDataSource<any>;
  role:string;
  userId:any;
  isDesktop:boolean;
  demandList: any[];
  vehicleDemandList: any[];
  @Input() vehicle: string;
  demandlist$: Observable<any>;
  openCount: number;

  /**
   * Setup Dependancy Injection
   */
  constructor(private demandService:DemandService,private platformService: PlatformService,private userInfoService:UserinfoService) {
    this.dataSource = new MatTableDataSource([]);
    this.demandlist$ = this.demandService.getDemandList();
   }

  /**
  * Subscribe to demandList Observable and filter demandList with vehicleId.
  */
  ngOnInit() {
    this.isDesktop = this.platformService.isDesktop();
    this.demandlist$.subscribe(demandList=> {
      this.demandList = demandList.data;
      this.vehicleDemandList = demandList.data.filter(id=> this.vehicle == id['vehicle']);
      this.dataSource.data = this.vehicleDemandList;
    } )
    this.userInfoService.getCurrentUserInfo().subscribe(info=> {
      this.userId = info.userId;
      this.role = info.role});
  }

  /**
   * Present Value Of Number of OpenDemand.
   */
  get openDemandCount(){
    const openCount = this.demandList?.filter(demand=> demand.status == 'O' && this.vehicle == demand['vehicle']);
    return openCount?.length
  }

  /**
   * Present Value Of Number of PickedDemand.
   */
  get pickedDemandCount(){
    const pickedCount = this.demandList?.filter(demand=> demand.status == 'P' && this.vehicle == demand['vehicle']);
    return pickedCount?.length
  }

  /**
   * Present Value Of Number of InProgressDemand.
   */
  get progressDemandCount(){
    const progressCount = this.demandList?.filter(demand=> demand.status == 'I' && this.vehicle == demand['vehicle']);
    return progressCount?.length
  }

  /**
   * Present Value Of Number of ClosedDemand.
   */
  get closedDemandCount(){
    const closedCount = this.demandList?.filter(demand=> demand.status == 'C' && this.vehicle == demand['vehicle']);
    return closedCount?.length
  }

  /**
   * Username From userinfo Service as Observable which we Subscribe from Html using Async pipe.
   * @param userId Id of User Handles Demand.
   * @returns Observable<string>
   */
  getUserName(userId){
    if(userId){
      return this.userInfoService.getUserInfo(userId).pipe(map(result => result.username));
    }
  }

   /**
   * Call DemandService Method getStatusText.
   * @param status 
   * @returns string with full Text insted of Character.
   */
  getStatusText(status){
    return this.demandService.getStatusText(status);
  }

  /**
   * Call to Function in Service which determines if The User has Access To Edit Demand.
   */
  canAccessDemand(demand){
    this.demandService.canAccessDemand(demand);
  }

 /**
  * Call to Function in Service which Navigates to Demand Component.
  */
  goToAddDemand(demand){
    this.demandService.goToAddDemand(demand);
  }

}
