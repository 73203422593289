import { UntypedFormGroup } from '@angular/forms';
import { Component, Input } from '@angular/core';
import { InputBase } from './input-base';

/**
 * DynamicInventoryInput Component.
 */
@Component({
    selector: 'app-dynamic-inventory-input',
    templateUrl: './dynamic-inventory-input.component.html',
    styleUrls: ['./dynamic-inventory-input.component.scss'],
})
export class DynamicInventoryInputComponent{
    @Input() inventory: InputBase<string>;
    @Input() form: UntypedFormGroup;
    
    /**
     * check if Formcontrol value is Valid or not.
     */
    get isValid() { return this.form.controls[this.inventory.key].valid; }

    /**
     * check if Formcontrol value is Touched or not.
     */
    get isTouched() { return this.form.controls[this.inventory.key].touched; }
}
